import React, { useEffect, useState, useRef } from "react";
import CodeList from "../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

const codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

export default function CardLawPartner() {
  const [partners, setPartners] = useState([
    {
      bIDX: "1",
      bLogo: "logo_buddible_200.png",
      bBranchName: "버디블",
      bBranchTYPE: "0",
      bCEOName: "윤성준",
      bBranchDate: "2020-09-19",
      bUserName: "담당자",
      bTel: "070-5088-0123",
      bEmail: "buddible@buddible.com",
      bService: "소프트웨어 개발업",
      bMemo:
        "안녕하십니까.\n저희는웹& 앱플랫폼서비스를제공하는회사입니다.\n\n- 고객의니즈와방향성을함께고민한다.\n- 더나은방향과목표를고객과함께한다.\n- 고객이믿고맡길수있도록신뢰감을줘야한다.\n\n위의세가지항목을중심으로고객과함께성장하는플랫폼개발회사가되도록하겠습니다.",
      bAddrFull: null,
      bAddrRoadAddress: "경기 고양시 일산동구 숲속마을1로 26",
      bAddrSido: null,
      bAddrSigungu: null,
      bAddrRoadname: null,
      bAddrKey: null,
      bMapTime: "1632023771600",
      bMapKey: "27f4w",
      bShowYN: "Y",
      bOrder: "0",
    },
  ]);

  const [partnerDetail, setPartnerDetail] = useState({
    bIDX: "1",
    bLogo: "logo_buddible_200.png",
    bBranchName: "버디블",
    bBranchTYPE: "0",
    bCEOName: "윤성준",
    bBranchDate: "2020-09-19",
    bUserName: "담당자",
    bTel: "070-5088-0123",
    bEmail: "buddible@buddible.com",
    bService: "소프트웨어 개발업",
    bMemo:
      "안녕하십니까.\n저희는웹& 앱플랫폼서비스를제공하는회사입니다.\n\n- 고객의니즈와방향성을함께고민한다.\n- 더나은방향과목표를고객과함께한다.\n- 고객이믿고맡길수있도록신뢰감을줘야한다.\n\n위의세가지항목을중심으로고객과함께성장하는플랫폼개발회사가되도록하겠습니다.",
    bAddrFull: null,
    bAddrRoadAddress: "경기 고양시 일산동구 숲속마을1로 26",
    bAddrSido: null,
    bAddrSigungu: null,
    bAddrRoadname: null,
    bAddrKey: null,
    bMapTime: "1632023771600",
    bMapKey: "27f4w",
    bShowYN: "Y",
    bOrder: "0",
  });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    requestBranchSelect();
  }, []);

  const requestBranchSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/branch/JS_branch_select_by_home.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setPartners([...newData["returnData"]]);
        }
      }
    });
  };

  const partnerModalOpen = (data) => {
    setPartnerDetail({ ...data });
    setModalOpen(true);
    socket.sendLocalMessage(
      MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
      "CardLogisPartner",
      { ...data },
      () => {}
    );
  };

  return (
    <>
      <CardLawPartnerModal partnerDetail={partnerDetail} />
      <section id="cardLawPartner" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>CARDLAW 가맹점</h2>
            <p>CARDLAW 가맹점 소개</p>
          </div>
          <div className="row">
            {partners.map((partner, index) => {
              let delayTime = `${(index + 1) * 100}`;
              return (
                <div
                  className="col-xl-4 col-md-6 d-flex align-items-stretch mb-4"
                  key={index}
                  data-aos="zoom-in"
                  data-aos-delay={delayTime}
                >
                  <div className="icon-box w-100">
                    <div
                      className="icon"
                      style={{
                        background: `url(//cardlaw.co.kr/uploadFiles/logo/${partner.bLogo}) 50% 50%/contain no-repeat`,
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <h4 className="mb-0">
                      {partner.bHomepage ? (
                        <a
                          href={
                            partner.bHomepage.startsWith("http")
                              ? partner.bHomepage
                              : `http://${partner.bHomepage}`
                          }
                          target="_blank"
                        >
                          {partner.bBranchName}
                        </a>
                      ) : (
                        ""
                      )}
                    </h4>
                    <p className="text-secondary mb-2">{partner.bService}</p>
                    <p className="crop-text-3">{partner.bMemo}</p>
                    <span
                      className="btn btn-sm btn-secondary mt-3"
                      data-toggle="modal"
                      data-target="#partnerDetailModal"
                      onClick={() => {
                        partnerModalOpen({ ...partner });
                      }}
                    >
                      상세보기
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

function CardLawPartnerModal({ partnerDetail }) {
  let bMapTime = partnerDetail.bMapTime;
  let bMapKey = partnerDetail.bMapKey;
  const bMapObj = useRef();

  let [mapView, setMapView] = useState(false);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
      "CardLawPartnerModal",
      (b, n) => {
        console.log("EVENT_HOMEPAGE_DRAW_MODAL_MAP", n);
        bMapKey = n.bMapKey;
        bMapTime = n.bMapTime;
        bMapObj.current.innerHTML = "";
        if (bMapTime === "") return;
        setTimeout(() => {
          bMapObj.current.id = `daumRoughmapContainer${bMapTime}`;
          new window.daum.roughmap.Lander({
            timestamp: bMapTime,
            key: bMapKey,
            mapWidth: "436",
            mapHeight: "360",
          }).render();
        }, 500);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_HOMEPAGE_DRAW_MODAL_MAP,
        "CardLawPartnerModal"
      );
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="partnerDetailModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {partnerDetail.bBranchName}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row pb-3">
                <div className="col">
                  <div
                    className="image"
                    style={{
                      width: "100%",
                      height: "125px",
                      background: `url(//cardlaw.co.kr/uploadFiles/logo/${partnerDetail.bLogo}) 50% 50% / contain no-repeat`,
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-2">
                  <p className="font-weight-bold">소개글</p>
                  <p
                    className="w-100"
                    style={{
                      whiteSpace: "break-spaces",
                      wordBreak: "break-all",
                    }}
                  >
                    {partnerDetail.bMemo}
                  </p>
                </div>
                {partnerDetail.bHomepage && (
                  <div className="col-12 py-2">
                    <p className="font-weight-bold">홈페이지</p>
                    <a
                      className="text-primary text-decoration-underline"
                      href={
                        partnerDetail.bHomepage.startsWith("http")
                          ? partnerDetail.bHomepage
                          : `http://${partnerDetail.bHomepage}`
                      }
                      target="_blank"
                    >
                      {partnerDetail.bHomepage.startsWith("http")
                        ? partnerDetail.bHomepage
                        : `http://${partnerDetail.bHomepage}`}
                    </a>
                  </div>
                )}

                <div className="col-6 py-2">
                  <p className="font-weight-bold">설립일</p>
                  <p>{partnerDetail.bBranchDate}</p>
                </div>

                <div className="col-6 py-2">
                  <p className="font-weight-bold">대표자</p>
                  <p>{partnerDetail.bCEOName}</p>
                </div>
                <div className="col-6 py-2">
                  <p className="font-weight-bold">담당자</p>
                  <p>{partnerDetail.bUserName}</p>
                </div>
                <div className="col-6 py-2">
                  <p className="font-weight-bold">전화번호</p>
                  <p>{partnerDetail.bTel}</p>
                </div>

                <div className="col-12 py-2">
                  <p className="font-weight-bold">e-mail</p>
                  <p>{partnerDetail.bEmail}</p>
                </div>
                <div className="col-12 py-2">
                  <p className="font-weight-bold">주소지</p>
                  <p>{partnerDetail.bAddrRoadAddress}</p>
                </div>
                <div
                  className="col-12 bg-light
                root_daum_roughmap root_daum_roughmap_landing
                "
                  id="test"
                  ref={bMapObj}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
