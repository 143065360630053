import Utilities from "./Utilities";
import axios from "axios";

export const MsgIDList = {
  EVENT_USER_MANAGE_FILTER_CHANGED: "EVENT_USER_MANAGE_FILTER_CHANGED",
  EVENT_USER_MANAGE_USER_UPDATED: "EVENT_USER_MANAGE_USER_UPDATED",
  EVENT_USER_MANAGE_USER_INSERTED: "EVENT_USER_MANAGE_USER_INSERTED",
  EVENT_USER_MANAGE_EDIT_MODAL_CALL: "EVENT_USER_MANAGE_EDIT_MODAL_CALL",

  EVENT_BRANCH_MANAGE_FILTER_CHANGED: "EVENT_BRANCH_MANAGE_FILTER_CHANGED",
  EVENT_BRANCH_MANAGE_DATA_UPDATED: "EVENT_BRANCH_MANAGE_DATA_UPDATED",
  EVENT_BRANCH_MANAGE_DATA_INSERTED: "EVENT_BRANCH_MANAGE_DATA_INSERTED",
  EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL: "EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL",

  EVENT_BRANCH_PP_MANAGE_FILTER_CHANGED: "EVENT_BRANCH_PP_MANAGE_FILTER_CHANGED",
  EVENT_BRANCH_PP_MANAGE_DATA_UPDATED: "EVENT_BRANCH_PP_MANAGE_DATA_UPDATED",
  EVENT_BRANCH_PP_MANAGE_DATA_INSERTED: "EVENT_BRANCH_PP_MANAGE_DATA_INSERTED",
  EVENT_BRANCH_PP_MANAGE_EDIT_MODAL_CALL: "EVENT_BRANCH_PP_MANAGE_EDIT_MODAL_CALL",


  EVENT_FAQ_MANAGE_FILTER_CHANGED: "EVENT_FAQ_MANAGE_FILTER_CHANGED",
  EVENT_FAQ_MANAGE_DATA_UPDATED: "EVENT_FAQ_MANAGE_DATA_UPDATED",
  EVENT_FAQ_MANAGE_DATA_INSERTED: "EVENT_FAQ_MANAGE_DATA_INSERTED",
  EVENT_FAQ_MANAGE_EDIT_MODAL_CALL: "EVENT_FAQ_MANAGE_EDIT_MODAL_CALL",

  EVENT_PAYMENT_REGISTER_DATA_CHANGED: "EVENT_PAYMENT_REGISTER_DATA_CHANGED",
  EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL:
    "EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL",
  EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED:
    "EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED",


  EVENT_PAYMENT_LIST_SMS_OPEN: "EVENT_PAYMENT_LIST_SMS_OPEN",

  EVENT_PAYMENT_LIST_FILTER_CHANGED: "EVENT_PAYMENT_LIST_FILTER_CHANGED",
  EVENT_PAYMENT_LIST_DATA_CHANGED: "EVENT_PAYMENT_LIST_DATA_CHANGED",
  EVENT_PAYMENT_LIST_DATA_CLEAR_ALL: "EVENT_PAYMENT_LIST_DATA_CLEAR_ALL",
  EVENT_PAYMENT_LIST_NEED_REFRESH: "EVENT_PAYMENT_LIST_NEED_REFRESH",
  EVENT_PAYMENT_LIST_SUB_NEED_REFRESH: "EVENT_PAYMENT_LIST_SUB_NEED_REFRESH",

  EVENT_HOMEPAGE_DRAW_MODAL_MAP: "EVENT_HOMEPAGE_DRAW_MODAL_MAP",

  EVENT_CARD_LOGIS_RECEIPT_DATA_CHANGE: "EVENT_CARD_LOGIS_RECEIPT_DATA_CHANGE",
  EVENT_INIT_CODE_ACCOUNT_LEVEL: "EVENT_INIT_CODE_ACCOUNT_LEVEL",
  EVENT_INIT_CODE_BRANCH_TYPE: "EVENT_INIT_CODE_BRANCH_TYPE",
  EVENT_INIT_CODE_FAQ_TYPE: "EVENT_INIT_CODE_FAQ_TYPE",
  EVENT_INIT_CODE_PAYMENT_TAX_TYPE: "EVENT_INIT_CODE_PAYMENT_TAX_TYPE",
  EVENT_INIT_CODE_PAYMENT_STATUE: "EVENT_INIT_CODE_PAYMENT_STATUE",
  EVENT_LOGIN_AUTO_FAIL: "EVENT_LOGIN_AUTO_FAIL",
  EVENT_LOGIN_AUTO_SUCCESS: "EVENT_LOGIN_AUTO_SUCCESS",
  EVENT_AUTO_RECONNECTED: "EVENT_AUTO_RECONNECTED",
};

let isDebug = false;

export default class BuddibleSocket {
  constructor() {
    if (BuddibleSocket.exists) {
      return BuddibleSocket.instance;
    }
    this._socketServer = null;
    this._eventListener = {};
    this._localEventListener = {};
    this._instanceEventListener = {};
    this._Dataset = {};
    this._localDataset = new Map();
    this._connectWaitingList = [];
    this._util = Utilities;
    this._isConnected = false;
    this._isReconnect = false;
    BuddibleSocket.instance = this;
    BuddibleSocket.exists = true;
    this.dataDserverUrl = "//cardlaw.co.kr/backside";
    return this;
  }

  //change socket ref
  changeServer = (str) => {
    this._socketServer = str;
  };

  isConnected = () => {
    return this._isConnected;
  };

  addLocalEventListener = (msgID, className, callback) => {
    //isDebug && console.log('addLocalEventListener', className);
    let listeners = [];
    if (this._localEventListener[msgID]) {
      listeners = [
        ...this._localEventListener[msgID],
        {
          className: className,
          callback: callback,
        },
      ];
    } else {
      listeners = [
        {
          className: className,
          callback: callback,
        },
      ];
    }
    this._localEventListener = {
      ...this._localEventListener,
      [msgID]: listeners,
    };
    //isDebug && console.log('_localEventListener',this._localEventListener);
  };

  removeLocalEventListener = (msgID, className) => {
    //isDebug && console.log('removeLocalEventListener',this._localEventListener);
    if (Array.isArray(this._localEventListener[msgID]))
      this._localEventListener[msgID] = this._localEventListener[msgID].filter(
        (item) => item.className !== className
      );
  };

  //send message to server
  sendLocalMessage = (msgID, className, data, callback) => {
    this.fireLocalEventListener(msgID, className, data, callback);
    //isDebug && console.log(e);
  };

  fireLocalEventListener = (msgID, className, newData, callback) => {
    let beforeData = this._localDataset.get(msgID);
    this._localDataset.set(msgID, newData);
    //isDebug && console.log(this._localEventListener,msgID,beforeData,newData);
    if (this._localEventListener.hasOwnProperty(msgID)) {
      //isDebug && console.log('noneListener in normal');

      if (this._localEventListener[msgID]) {
        for (let i = 0; i < this._localEventListener[msgID].length; i++) {
          try {
            if (this._localEventListener[msgID][i].className !== className)
              this._localEventListener[msgID][i].callback(beforeData, newData);
          } catch (e) {}
        }
      }
    }
  };

  getLocalDataSet = (msgID) => {
    return this._localDataset.get(msgID);
  };

  //send message to server
  sendMessage = (e, msgID, callback) => {
    //console.log("sendMessage");
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);
    axios
      .post(
        this.dataDserverUrl + e.file,
        {
          message: e,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log("axios handleData");
        isDebug && console.log("axios handleData", response.data);
        isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(
          result.msgId ? result.msgId : result.socketMsgID,
          this._Dataset[result.msgId],
          result,
          () => {}
        );
      })
      .catch((response) => {
        console.log("Error!");
      });

    this.writeLog();
  };

  //send message to server
  getShortTag = (e, msgID, callback) => {
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);

    //베스트관세 fc8c0faa42e483ea74e4d7f807ee6fe7be8e0753
    // 버디블 Authorization: `Bearer 8b73933f6a393237c29d452a03d4fa5358347f82`,
    axios
      .post(
        "https://api-ssl.bitly.com/v4/shorten",
        {
          ...e,
          domain: "bit.ly",
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer fc8c0faa42e483ea74e4d7f807ee6fe7be8e0753`,
          },
        }
      )
      .then((response) => {
        isDebug && console.log("axios handleData", response.data);
        isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(msgID, this._Dataset[msgID], result, () => {});
      })
      .catch((response) => {
        console.log("Error!");
      });

    this.writeLog();
  };

  handleData = (data) => {
    let result = JSON.parse(data);
    isDebug && console.log("socket handleData", result);
    if (result.method === MsgIDList.EVENT_CHAT_PAGE_MSG_EVENT) {
      this.fireLocalEventListener(
        result.method,
        this._Dataset[result.msgId],
        result
      );
    }
    this.fireEventListener(
      result.msgId ? result.msgId : result.socketMsgID,
      this._Dataset[result.msgId],
      result,
      () => {}
    );
  };

  addEventListener = (msgID, className, callback) => {
    if (!this._eventListener[msgID]) {
      this._eventListener = {
        ...this._eventListener,
        [msgID]: [
          {
            className: className,
            callback: callback,
          },
        ],
      };
    } else {
      this._eventListener[msgID] = [
        ...this._eventListener[msgID],
        {
          className: className,
          callback: callback,
        },
      ];
    }
    isDebug && console.log("_eventListener", this._eventListener);
  };

  addInstanceEventListener = (msgID, callback) => {
    //console.log(`addInstanceEventListener = (${msgID}, callback)`);
    if (!this._instanceEventListener[msgID]) {
      isDebug && console.log("no listener", this._instanceEventListener);
      this._instanceEventListener = {
        ...this._instanceEventListener,
        [msgID]: [
          {
            callback: callback,
          },
        ],
      };
      isDebug && console.log("no listener2", this._instanceEventListener);
    } else {
      isDebug && console.log("yes listener", this._instanceEventListener);
      this._instanceEventListener[msgID] = [
        ...this._instanceEventListener[msgID],
        {
          _instanceEventListener: callback,
        },
      ];
      isDebug && console.log("yes listener2", this._instanceEventListener);
    }
  };

  removeInstanceEventListener = (msgID) => {
    isDebug && console.log("removeInstanceEventListener", msgID);
    delete this._instanceEventListener[msgID];
  };

  removeEventListener = (msgID, className) => {
    this._eventListener[msgID] = this._eventListener[msgID].filter(
      (item) => item.className !== className
    );
  };

  fireEventListener = (msgID, beforeData, newData, callback) => {
    //console.log("fireEventListener msgID", msgID);
    //console.log(this._eventListener);
    // eslint-disable-next-line array-callback-return
    if (!this._eventListener[msgID]) {
      //console.log('noneListener in normal');
      //console.log(this._instanceEventListener);
      if (this._instanceEventListener[msgID]) {
        this._instanceEventListener[msgID].map((e) => {
          //console.log('fired event to instance event listener');
          try {
            e.callback(beforeData, newData);
          } catch (e) {}

          if (newData.socketMsgID) {
            this.removeInstanceEventListener(newData.socketMsgID);
          }
          return true;
        });
      }
    } else {
      //console.log('hihihi');
      this._eventListener[msgID].forEach((e) => {
        try {
          e.callback(beforeData, newData);
        } catch (e) {}
      });
      this._Dataset[msgID] = newData;
    }
  };

  writeLog = () => {
    /*
        this._socketServer = null;
        this._eventListener = {};
        this._instanceEventListener = {};
        this._Dataset = {};
         */
    if (isDebug) {
      console.log("_socketServer", this._socketServer);
      console.log("_eventListener", this._eventListener);
      console.log("_instanceEventListener", this._instanceEventListener);
      console.log("_Dataset", this._Dataset);
    }
  };

  waitingForConnect(className, callback) {
    if (this._isConnected) callback(true);

    this._connectWaitingList.push({ className: className, callback: callback });
  }

  responseWaitingForConnect() {
    for (let i = 0; i < this._connectWaitingList.length; i++) {
      let listItem = this._connectWaitingList[i];
      if (listItem.hasOwnProperty("callback")) listItem["callback"](true);
    }
    this._connectWaitingList = [];
  }

  handleOpen = () => {
    this._isConnected = true;
    if (!this._isReconnect) {
      this._isReconnect = true;
    } else {
      this.sendLocalMessage(
        MsgIDList.EVENT_AUTO_RECONNECTED,
        "BuddibleSocket",
        {},
        () => {}
      );
    }
    this.responseWaitingForConnect();
    isDebug && console.log("connected:)");
  };
  handleClose = () => {
    this._isConnected = false;
    isDebug && console.log("disconnected:(");
  };
}
