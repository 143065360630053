import React, { useState, useEffect } from "react";
import TermsNav from "./TermsNav";
import MainFooter from "../main/MainFooter";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import ElectronicFinancialTransactions from "./ElectronicFinancialTransactions";
import "./Terms.css";

export default function Terms() {
  let [pages, setPages] = useState([
    {
      id: "privacyPolicy",
      component: <PrivacyPolicy />,
      name: "개인정보처리방침",
    },
    {
      id: "termsOfService",
      component: <TermsOfService />,
      name: "서비스이용약관",
    },
    {
      id: "electronicFinancialTransactions",
      component: <ElectronicFinancialTransactions />,
      name: "전자금융거래 이용약관",
    },
  ]);

  return (
    <div
      id="sample1"
      className=" d-flex flex-column h-100"
      style={{ minHeight: "100vh" }}
    >
      <TermsNav pages={pages} />
      <div id="terms">
        {pages.map((page) => {
          if (window.location.hash === `#${page.id}`) {
            return <div style={{ marginTop: "72px" }}>{page.component}</div>;
          }
        })}
      </div>
      <MainFooter />
    </div>
  );
}
