import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function PaymentRequestSMS(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loginInfo, setLoginInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const $controller = {
    pmCompanyName: useRef(),
    pmCTel: useRef(),
    psProductName: useRef(),
    psBaseCost: useRef(),
    psTax: useRef(),
    psTotalCost: useRef(),
    uTel: useRef(),
    uName: useRef(),
    uCoTel: useRef(),
    uCoName: useRef(),
    uID: useRef(),
    psOrderURL: useRef(),
    msgText: useRef(),
  };

  useEffect(() => {
    if (userData) {
      $controller.pmCompanyName.current.value = userData.pmCompanyName;
      $controller.pmCTel.current.value = userData.pmCTel;
      $controller.psProductName.current.value = userData.pmProductName;
      $controller.psBaseCost.current.value = util.addCommas(
        userData.psBaseCost
      );
      $controller.psTax.current.value = util.addCommas(userData.psTax);
      $controller.psTotalCost.current.value = util.addCommas(
        userData.psTotalCost
      );
      //$controller.uTel.current.value = userData.uTel;
      $controller.uName.current.value = userData.uName;
      $controller.uCoTel.current.value = userData.uCoTel;
      //$controller.uCoName.current.value = userData.uCoName;
      //$controller.uID.current.value = userData.uID;
      $controller.psOrderURL.current.value = userData.psOrderURL;
      makeSMSTemplate();
    }
  }, [userData]);

  const makeSMSTemplate = () => {
    let msg = `${$controller.pmCompanyName.current.value} 의뢰인님
${$controller.psProductName.current.value} 에 대한 ${$controller.psTotalCost.current.value}원 결제 url입니다.

문의처 ${$controller.uName.current.value}
${$controller.uCoTel.current.value}

${$controller.psOrderURL.current.value} `;

    $controller.msgText.current.value = msg;
  };

  useEffect(() => {
    let isIE = window.ActiveXObject || "ActiveXObject" in window;
    if (isIE) {
      $(".modal").removeClass("fade");
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_SMS_OPEN,
      "PaymentRequestSMS",
      (b, n) => {
        setUserData(n.currentData);
        if (socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS)) {
          let li = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
          setLoginInfo(li.returnData[0]);
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_SMS_OPEN,
        "PaymentRequestSMS"
      );
    };
  }, []);

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        uID: userData.psCreator,
        msgText: $controller.msgText.current.value,
        pmCTel: $controller.pmCTel.current.value.replace(/^0-9/gi, ""),
        uCoName: userData.uCoName,
      };

      requestUserUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controller.pmCTel.current.value.trim() === "") {
      codeList.Modal.current.alert("전화번호를 입력해주세요.", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/sms/JS_sms_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);

          codeList.Modal.current.alert(
            "문자 발송을 요청 하였습니다.",
            () => {}
          );

          $("#btnCloseSMSModal").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            `문자 발송 요청중 오류가 발생하였습니다.`,
            () => {}
          );
        }
      }
    });
  };

  return (
    <>
      <div
        className={"modal fade"}
        id="staticPaymentRequestSMS"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog" style={{ maxWidth: 1000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>SMS발송</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseSMSModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      의뢰인(CLIENT)
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.pmCompanyName}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      의뢰인(CLIENT) 전화번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.pmCTel}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      사건명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        ref={$controller.psProductName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      공급가액
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.psBaseCost}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      세액
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.psTax}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      총금액
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.psTotalCost}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      담당자명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.uName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      담당자 전화번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        ref={$controller.uCoTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      결제 URL
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.psOrderURL}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      SMS양식
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        rows={10}
                        className="form-control"
                        placeholder=""
                        ref={$controller.msgText}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end"></label>
                    <div className="col-sm-9">
                      <button
                        className="btn btn-info"
                        onClick={makeSMSTemplate}
                      >
                        SMS양식 미리보기
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/*, uCoName, uCoCEOName, uCoAddr, uCoRegNo, uCoTel, uPGIDSamsung, uPGIDNoneSamsung*/}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
              >
                <i className="fa fa-check mr-2" />
                발송
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(PaymentRequestSMS);
