import React, { useState, useEffect } from "react";

export default function MainCarousel() {
  return (
    <section
      id="hero"
      className="d-flex align-items-center justify-content-center"
      style={{
        background: `url("assets/img/hero-bg.jpg") top /cover no-repeat`,
      }}
    >
      <div className="container" data-aos="fade-up">
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="col-xl-10 col-lg-8">
            <h1>변호사님!</h1>
            <h2
              className="mt-4"
              style={{
                fontSize: "2em",
                lineHeight: "1.2em",
              }}
            >
              사건 수임료 <strong>비대면 카드결제</strong> 받으실 때 "카드로"
              서비스로 간편하게 결제 받으세요
            </h2>
          </div>
        </div>
        <div
          className="row gy-4 mt-5 justify-content-center"
          data-aos="zoom-in"
          data-aos-delay="250"
        >
          <div className="col-xl-3 col-md-6">
            <div className="icon-box">
              <i className="bi bi-credit-card" />
              <h3 className="text-white">장점1</h3>
              <br />
              <p className="text-white">
                비대면 카드결제 요청시 카드번호를 받을 필요가 없어요.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="icon-box">
              <i className="bi bi-upload"></i>
              <h3 className="text-white">장점2</h3>
              <br />
              <p className="text-white">
                결제요청서에 사건 판결문등 문서를 첨부해서 전송할 수 있어요.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="icon-box">
              <i className="bi bi-code-slash" />
              <h3 className="text-white">장점3</h3>
              <br />
              <p className="text-white">
                사건명, 수임료등 변호사님들이 실제 사용하는 용어로 시스템이
                개발되어 있어요.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="icon-box">
              <i className="bi bi-clipboard-check" />
              <h3 className="text-white">장점4</h3>
              <br />
              <p className="text-white">
                결제요청건들의 결제, 보류, 취소 상태를 한눈에 볼 수 있어요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
