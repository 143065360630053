import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import PaymentRequestSMS from "./PaymentRequestSMS";

const codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

const AGENT_LEVEL = [
  { text: "가맹점", value: "0" },
  { text: "관리자", value: "1" },
  { text: "개발자", value: "3" },
];

const WORK_YN = [
  { text: "사용", value: "Y" },
  { text: "중지", value: "N" },
];

const PAY_STATUS = [
  { text: "대기중", value: "R" },
  { text: "결제완료", value: "P" },
  { text: "일부결제", value: "P1" },
  { text: "일부취소", value: "C1" },
  { text: "전체취소", value: "C" },
];
const TAX_TYPE = [
  { text: "과세", value: "A" },
  { text: "영세", value: "B" },
  { text: "면세", value: "C" },
];

const PAY_SUB_STATUS = [
  { text: "대기중", value: "R" },
  { text: "결제완료", value: "P" },
  { text: "취소완료", value: "C" },
];
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

//테이블의 헤더 영역(타이틀)
const headCells = [
  {
    field: "pmCreateDate", //데이터 필드명
    headerName: "생성일자", //설명 글
    numeric: false, //숫자 여부
    disablePadding: false, //각 셀별 padding 값
    valueGetter: (
      params //값 setting 방법
    ) => util.getDateToFormat(new Date(params.data), "YYYY-MM-DD"),
  },
  {
    field: "pmCompanyName",
    headerName: "의뢰인(CLIENT)",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "pmProductName",
    headerName: "사건명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "psBaseCost",
    headerName: "공급가액",
    type: "string",
    minWidth: 150,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },
  {
    field: "psTax",
    headerName: "세액",
    type: "string",
    minWidth: 150,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },
  {
    field: "pmTotalCost",
    headerName: "총금액",
    type: "string",
    minWidth: 150,
    numeric: true,
    disablePadding: false,
    valueGetter: (params) => util.addCommas(params.data),
  },

  {
    field: "psPayStatus",
    headerName: "결제상태",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => util.getCodeName(params.data, PAY_SUB_STATUS),

    renderCell: (params) => (
      <span
        className={
          params.data === "P"
            ? "text-primary"
            : params.data === "C"
            ? "text-danger"
            : ""
        }
      >
        {util.getCodeName(params.data, PAY_SUB_STATUS)}
      </span>
    ),
  },
  {
    field: "psOrderURL",
    headerName: "결제 URL",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => (
      <span>
        <div className="btn-toolbar justify-content-center" role="toolbar">
          <div className="input-group order-1 order-md-1 order-lg-1  bm-sm-0">
            <input
              id={params.data}
              className="form-control"
              value={params.data}
              readOnly={true}
            />
            <button
              className="btn btn-sm btn-primary custom-bg-info input-group-addon "
              onClick={(e) => {
                let copyText = document.getElementById(params.data);
                copyText.select();
                document.execCommand("Copy");
              }}
              alt={"복사"}
              aria-label={"복사"}
            >
              <i className="fas fa-copy " />
            </button>
          </div>
        </div>
      </span>
    ),
  },
  {
    field: "pmCTel",
    headerName: "SMS발송",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => (
      <button
        className="btn btn-sm btn-info custom-bg-info"
        data-toggle="modal"
        data-target="#staticPaymentRequestSMS"
        onClick={(e) => {
          socket.sendLocalMessage(
            MsgIDList.EVENT_PAYMENT_LIST_SMS_OPEN,
            "UserManagementTable",
            params,
            () => {}
          );
        }}
      >
        <i className="fas fa-comment-alt " />
      </button>
    ),
  },
  {
    field: "psCancelURL",
    headerName: "승인취소",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {(params.currentData.psPayStatus === "P" ||
          params.currentData.psPayStatus === "") && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger btn-sm ml-2"
            onClick={(e) => params.cancelOrder(e, params)}
            disabled={params.cancelBillStatus}
          >
            <i className="fas fa-credit-card " />
            {params.cancelBillStatus && (
              <span
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        )}
      </span>
    ),
  },
  {
    field: "psCancelURL-1",
    headerName: "삭제",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        {params.currentData.psPayStatus === "R" && (
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-danger btn-sm ml-2"
            onClick={(e) => params.deleteOrder(e, params)}
            disabled={params.cancelBillStatus}
          >
            <i className="fas fa-times " />
            {params.cancelBillStatus && (
              <span
                className="spinner-border spinner-border-sm ml-2"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        )}
      </span>
    ),
  },
];

const PaymentDetailView = ({ rowData }) => {
  return (
    <tr className="container" id={rowData.id}>
      <td colSpan={headCells.length + 1} className="bg-light">
        {/*결제정보*/}
        <div className="card mb-1">
          <div className="card-header custom-header-darkGray">
            <div className="card-title font-weight-bold">결제정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">총결제금액</h6>{" "}
                <p>{util.addCommas(rowData.psTotalCost)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">공급가액</h6>{" "}
                <p>{util.addCommas(rowData.psBaseCost)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">부가세</h6>{" "}
                <p>{util.addCommas(rowData.psTax)} 원</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">면세금액</h6>{" "}
                <p>{util.addCommas(0)} 원</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">결제번호 PG TID</h6>{" "}
                <p>{rowData.dc_TID}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">주문 번호</h6>{" "}
                <p>{rowData.psOderIDSub}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">구매영수증</h6>{" "}
                <a
                  className="btn btn-outline-secondary btn-sm"
                  href={`//cardlaw.co.kr/backside/danal_receipt.php?cpid=${rowData.dc_CPID}&TID=${rowData.dc_TID}&AMOUNT=${rowData.dc_AMOUNT}&cpkey=${rowData.dc_CPKEY}`}
                  target="popup"
                  onClick={(e) => {
                    window.open(
                      `//cardlaw.co.kr/backside/danal_receipt.php?cpid=${rowData.dc_CPID}&TID=${rowData.dc_TID}&AMOUNT=${rowData.dc_AMOUNT}&cpkey=${rowData.dc_CPKEY}`,
                      "popup",
                      "width=600,height=600"
                    );
                  }}
                >
                  영수증보기
                </a>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">첨부파일</h6>{" "}
                {rowData.pmFiles !== "" &&
                  rowData.pmFiles.split("|").map((v, i) => {
                    let splitData = v.split(":");
                    if (splitData.length === 1) return null;
                    let fileName = splitData[0];
                    let fileLink = `//cardlaw.co.kr/uploadFiles/attach/${splitData[1]}`;
                    return (
                      <div className="mb-2" key={`prev_${v}`}>
                        <a
                          className="btn btn-sm btn-info custom-bg-info mr-3"
                          href={`//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                          target="_blank"
                        >
                          <i className="fa fa-download mr-2" /> Down
                        </a>
                        {fileName.endsWith(".pdf") && (
                          <a
                            className="font-weight-bold text-decoration-underline"
                            href={fileLink}
                            download={fileName}
                            target="_blank"
                          >
                            {fileName}
                          </a>
                        )}
                        {!fileName.endsWith(".pdf") && (
                          <a
                            className="font-weight-bold text-decoration-underline"
                            href={`//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                            target="_blank"
                          >
                            {fileName}
                          </a>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        {/*사용자정보*/}
        <div className="card mb-1">
          <div className="card-header custom-header-darkGray">
            <div className="card-title font-weight-bold">결제자 정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">의뢰인(CLIENT)</h6>{" "}
                <p>{rowData.pmCompanyName}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">이메일</h6>{" "}
                <p>{rowData.dc_email}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">주소</h6>{" "}
                <p>{rowData.u_addr}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">전화번호</h6>{" "}
                <p>{rowData.pmCTel}</p>
              </div>
            </div>
          </div>
        </div>
        {/*카드정보*/}
        <div className="card">
          <div className="card-header custom-header-darkGray">
            <div className="card-title font-weight-bold">카드 결제정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드사명</h6>{" "}
                <p>{rowData.CARDNAME}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">할부기간</h6>{" "}
                <p>{rowData.dc_QUOTA}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드번호</h6>{" "}
                <p>{rowData.dc_CARDNO}</p>
              </div>
              <div className="col-6 col-xxl-3">
                <h6 className="font-weight-bold">카드승인번호</h6>{" "}
                <p>{rowData.dc_CARDAUTHNO}</p>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

function Row(props) {
  const {
    row,
    isMultiSelect = false,
    parentData,
    cancelOrder,
    cancelBillStatus,
    deleteOrder,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  cancelOrder: cancelOrder,
                  cancelBillStatus: cancelBillStatus,
                  deleteOrder: deleteOrder,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,

                  cancelOrder: cancelOrder,
                  cancelBillStatus: cancelBillStatus,
                  deleteOrder: deleteOrder,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <PaymentDetailView rowData={row} parentData={parentData} />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

//테이블 헤드 생성자
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            background: "#212121",
            color: "#ffffff",
          }}
        />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
            className={classes.theadBold}
            style={{
              background: "#212121",
              color: "#ffffff",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
  theadBold: {
    fontWeight: 900,
  },
}));

const _mClassName = "PaymentRequestTable";

//테이블 전체 생성자
export default function PaymentRequestTable({
  isServerPaging = true,
  isServerSort = true,
  userInfo,
}) {
  const [cancelBillStatus, setCancelBillStatus] = useState(false);

  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("pmCreateDate");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "pmCreateDate",
      sort: "desc",
    },
  ]);
  let filters = {};

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = n;
        userInfo.uAgreeYN === "Y" && requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
      _mClassName,
      (b, n) => {
        userInfo.uAgreeYN === "Y" && requestUserSelect();
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (userInfo.uAgreeYN === "Y") {
      requestUserSelect();
    }
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/payment/JS_payment_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    if (Number(userInfo.uLevel) < 1) {
      socketMsg.uID = userInfo.uID;
    }
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );

          //console.log(ServerData);
          socket.sendLocalMessage(
            MsgIDList.EVENT_PAYMENT_LIST_SUB_NEED_REFRESH,
            _mClassName,
            {},
            () => {}
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  const cancelOrder = (e, params) => {
    codeList.Modal.current.confirm("결제승인을 취소하시겠습니까?", (ret) => {
      if (ret === true) {
        // 팝업 창 열기
        let popup = window.open(
          "",
          "popupWindow",
          "width=600,height=400,scrollbars=yes"
        );

        // 폼 생성
        let form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute(
          "action",
          "https://cardlaw.co.kr/backside/billgate/CancelInput.php"
        );
        form.setAttribute("target", "popupWindow");

        let data = {
          CPID: params.currentData.dc_CPID,
          CRYPTOKEY: params.currentData.dc_CPKEY,
          TID: params.currentData.dc_TID,
          AMOUNT: params.currentData.dc_AMOUNT,
          psOderIDSub: params.currentData.psOderIDSub,
        };
        // 데이터 추가
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", data[key]);
            form.appendChild(hiddenField);
          }
        }

        // 폼을 문서에 추가하고 제출
        document.body.appendChild(form);
        form.submit();

        // 폼을 문서에서 제거
        document.body.removeChild(form);

        codeList.Modal.current.alert(
          `결제 취소 후 리스트를 새로 조회 하여 주십시요.`,
          () => {}
        );
        /*setCancelBillStatus(true);
        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/billgate/CancelInput.php",
          msgID: msgID,
          data: {},
          CPID: params.currentData.dc_CPID,
          CRYPTOKEY: params.currentData.dc_CPKEY,
          TID: params.currentData.dc_TID,
          AMOUNT: params.currentData.dc_AMOUNT,
          psOderIDSub: params.currentData.psOderIDSub,
        };
        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
          setCancelBillStatus(false);
          if (newData) {
            if (newData["ret"] === true) {
              setLoading(false);
              socket.sendLocalMessage(
                MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
                "PaymentRequestTableSub",
                newData,
                () => {}
              );
              codeList.Modal.current.alert(
                `결제취소가 정상적으로 진행되었습니다.\n ${newData.RETURNMSG}`,
                () => {}
              );

              params.currentData.psPayStatus = "C";
              requestUserSelect();
            } else {
              setLoading(false);
              codeList.Modal.current.alert(
                `결제취소중 오류가 발생하였습니다. 관리자에게 문의 해주세요.\n ${newData.RETURNMSG}`,
                () => {}
              );
            }
          }
        });*/
      }
    });
  };

  const deleteOrder = (e, params) => {
    //setLoading(true);
    codeList.Modal.current.confirm("결제 요청서를 삭제하시겠습니까?", (ret) => {
      if (ret === true) {
        setCancelBillStatus(true);
        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/payment/JS_payment_request_update.php",
          msgID: msgID,
          data: {},
          CPID: params.currentData.dc_CPID,
          TID: params.currentData.dc_TID,
          AMOUNT: params.currentData.dc_AMOUNT,
          psOderIDSub: params.currentData.psOderIDSub,
          psOderID: params.currentData.psOderID,
        };
        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
          setCancelBillStatus(false);
          if (newData) {
            if (newData["ret"] === true) {
              setLoading(false);
              socket.sendLocalMessage(
                MsgIDList.EVENT_PAYMENT_LIST_NEED_REFRESH,
                "PaymentRequestTableSub",
                newData,
                () => {}
              );
              codeList.Modal.current.alert(
                `결제 요청서가 정상적으로 삭제되었습니다.`,
                () => {}
              );

              params.currentData.psPayStatus = "C";
              requestUserSelect();
            } else {
              setLoading(false);
              codeList.Modal.current.alert(
                `결제요청서 삭제 중 오류가 발생하였습니다. 관리자에게 문의 해주세요.`,
                () => {}
              );
            }
          }
        });
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" id="paymentRequestList">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  cancelOrder={cancelOrder}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  cancelOrder={cancelOrder}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  cancelOrder={cancelOrder}
                  cancelBillStatus={cancelBillStatus}
                  deleteOrder={deleteOrder}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                cancelOrder={cancelOrder}
                cancelBillStatus={cancelBillStatus}
                deleteOrder={deleteOrder}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <PaymentRequestSMS />
    </TableContainer>
  );
}

PaymentRequestTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
