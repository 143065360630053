import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";
import CodeList from "./CodeList";

let util = new Utilities();
let loginMSG = {};
let codeList = new CodeList();

const AutoLogin = () => {
  let socket = new BuddibleSocket();
  // console.log("AutoLogin Ready!");
  // console.log(sessionStorage, localStorage);
  let loginInfo = sessionStorage.getItem("loginInfo");
  if (loginInfo === null) loginInfo = localStorage.getItem("loginInfo");

  try {
    loginInfo = util.buddibleDecrypt(loginInfo);
    loginInfo = JSON.parse(loginInfo);
  } catch (e) {
    loginInfo = null;
    sessionStorage.removeItem("loginInfo");
  }

  if (loginInfo === null) {
    loginInfo = localStorage.getItem("loginInfo");
    try {
      loginInfo = util.buddibleDecrypt(loginInfo);
      loginInfo = JSON.parse(loginInfo);
    } catch (e) {
      loginInfo = null;
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
    }
  }

  if (loginInfo === null) {
    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      "AutoLogin",
      {},
      () => {}
    );
    /*console.log("loginInfo === null");
    alert("로그인 후 이용해주십시요");
    window.location.replace("/");*/
    console.log("loginInfo is null");
  }
  let now = new Date();

  if (loginInfo) {
    if (loginInfo.storageLifeTime < now) {
      loginInfo = null;
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
    }
  }

  useEffect(() => {
    //console.log("AutoLogin waitingForConnect!");
    if (loginInfo === null) {
      socket.sendLocalMessage(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "AutoLogin",
        {},
        () => {}
      );
      console.log("useEffect loginInfo === null");
      alert("로그인 후 이용해주십시요");
      window.location.replace("/LoginPage");
      console.log("loginInfo is null");
      return;
    }
    loginToServer();
    socket.addLocalEventListener(
      MsgIDList.EVENT_AUTO_RECONNECTED,
      "AutoLogin",
      (beforeData, newData) => {
        loginToServer();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_AUTO_RECONNECTED,
        "AutoLogin",
        (beforeData, newData) => {
          loginToServer();
        }
      );
    };
  }, []);

  const loginToServer = () => {
    let msgID = util.makeUUIDv4();
    //console.log(loginInfo);
    if (
      !loginInfo.hasOwnProperty("userID") ||
      !loginInfo.hasOwnProperty("userPWD")
    ) {
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
      return;
    }
    loginMSG = {
      ...loginInfo,
    };

    socket.sendMessage(loginMSG, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log("newData", newData);
        if (newData["ret"]) {
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 1);
          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "AutoLogin",
            newData,
            () => {}
          );
        } else {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_FAIL,
            "AutoLogin",
            {},
            () => {}
          );
          alert("로그인 후 이용해주십시요");
          window.location.replace("/");
          console.log("AutoLogin EVENT_LOGIN_AUTO_FAIL");
        }
      }
      this.loginErrCheck(newData);
    });
  };
  return <></>;
};

export default AutoLogin;
