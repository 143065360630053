import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import MainNav from "../main/MainNav";
import MainFooter from "../main/MainFooter";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import AutoLogin from "../lib/AutoLogin";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "EditPassword";

let loginInfo = null;
export default function EditPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const controller = {
    user_pw: useRef(),
    new_pw: useRef(),
    new_check: useRef(),
  };

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (beforeData, newData) => {
        console.log(newData);
        if (newData && newData["returnData"][0]) {
          loginInfo = newData["returnData"][0];
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (beforeData, newData) => {
        alert("로그인이 필요한 서비스입니다.");
        window.location.replace("/");
      }
    );
  })();

  useEffect(() => {
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  function _checkValidation() {
    if (controller.user_pw.current.value.trim() === "") {
      codeList.Modal.current.alert("현재 비밀번호를 입력해주세요.");
      return true;
    }
    if (controller.new_pw.current.value.trim() === "") {
      codeList.Modal.current.alert("새 비밀번호를 입력해주세요.");
      return true;
    }
    if (controller.new_check.current.value.trim() === "") {
      codeList.Modal.current.alert("새 비밀번호 확인을 입력해주세요.");
      return true;
    }
    if (
      controller.new_pw.current.value.trim() !==
      controller.new_check.current.value.trim()
    ) {
      codeList.Modal.current.alert("새 비밀번호가 일치하지 않습니다.");
      return true;
    }
    return false;
  }

  function _requestUpdatePassword() {
    if (_checkValidation()) return;

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/account/JS_password_update.php",
      msgID: msgID,
      data: {},
      user_id: loginInfo.uID,
      user_tel: loginInfo.uTel,
      user_mail: loginInfo.uEmail,
      user_pw: controller.user_pw.current.value,
      new_pw: util.buddibleEncrypt(controller.new_pw.current.value),
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        setIsLoading(false);
        codeList.Modal.current.alert(newData["MSG"], () => {
          if (newData["ret"]) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/");
          }
        });
      }
    });
  }

  return (
    <div
      id="sample1"
      className=" d-flex flex-column h-100 bg-light"
      style={{ minHeight: "100vh" }}
    >
      <AutoLogin />
      <MainNav isNotShowGNB={true} />
      <div className="main flex-fill">
        <div
          className="flex-grow-1 d-flex flex-row justify-content-center align-items-center"
          style={{ marginTop: 80 }}
        >
          <div className="py-4" style={{ minWidth: 400 }}>
            <h5 className="font-weight-bold">비밀번호 변경</h5>
            <p>비밀번호로 내 정보를 안전하게 보호하세요</p>
            <input
              type="password"
              className="form-control rounded-0 mb-2"
              placeholder="현재 비밀번호"
              ref={controller.user_pw}
            />
            <input
              type="password"
              className="form-control rounded-0 border-bottom-0"
              placeholder="새 비밀번호"
              ref={controller.new_check}
            />
            <input
              type="password"
              className="form-control rounded-0 mb-4"
              placeholder="새 비밀번호 확인"
              ref={controller.new_pw}
            />
            <button
              className="w-100 btn btn-primary mb-2"
              disabled={isLoading}
              onClick={_requestUpdatePassword}
            >
              변경
              {isLoading && (
                <i className="fas fa-spinner ml-2 rotate-infinite" />
              )}
            </button>
            <Link
              to="/"
              className="w-100 btn btn-outline-secondary"
              disabled={isLoading}
            >
              취소
            </Link>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}
