import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MainFooter() {
  return (
    <footer id="footer" className="p-0">
      <div className="footer-top p-0 py-5">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-around">
            <div className="footer-info mb-0 text-center col-auto">
              <img src="logo/CARDLAW_logo(white).png" />
              <p className="mt-2">AM 09:00~ PM 06:00</p>
              <p>토, 일, 공휴일 휴무</p>
            </div>
            <div className="ml-3 ml-lg-0">
              <div className="mb-1">
                상호: ㈜플레이영종 | 대표이사:김도윤
                <br />
                사업자등록번호 : 810-87-02575 | 통신판매업신고:
                제2021-인천중구-0847호
                <br />
                고객센터 : 032-747-0211 | e-mail : cardlogis@gmail.com
                <br />
                주소 : 인천광역시 중구 흰바위로59번길8, 219호(운서동)
                <br />
                <p className="mt-2">
                  <Link
                    className="text-gold"
                    to={{
                      pathname: "/Terms",
                      hash: "#privacyPolicy",
                    }}
                    target="_blank"
                  >
                    개인정보 처리방침 및 이용동의약관
                  </Link>
                  <span> | </span>
                  <Link
                    className="text-gold"
                    to={{
                      pathname: "/Terms",
                      hash: "#termsOfService",
                    }}
                    target="_blank"
                  >
                    서비스이용약관
                  </Link>
                </p>
              </div>
              <div className="copyright text-left pt-1">
                &copy; Copyright{" "}
                <strong>
                  <span>CARDLAW</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
