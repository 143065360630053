import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function FaqManageTable(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const $fIDX = useRef();
  const $fBtype = useRef();
  const $fTITLE = useRef();
  const $fCONTENTS = useRef();
  const $fUSEYN = useRef();

  const dataChange = (e, params) => {
    //console.log(userData, params);
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      //console.log(userData.currentData);
      $fIDX.current.value = userData.currentData.fIDX;
      $fIDX.current.disabled = true;
      $fBtype.current.value = userData.currentData.fStype;
      $fTITLE.current.value = userData.currentData.fTITLE;
      $fCONTENTS.current.value = userData.currentData.fCONTENTS;
      $fUSEYN.current.value = userData.currentData.fUSEYN;
    } else {
      $fIDX.current.value = "";
      $fIDX.current.disabled = true;
      $fBtype.current.value = "";
      $fTITLE.current.value = "";
      $fCONTENTS.current.value = "";
      $fUSEYN.current.value = "";
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      "FaqManageEdit",
      (b, n) => {
        console.log("EVENT_FAQ_MANAGE_EDIT_MODAL_CALL");
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
        "FaqManageEdit"
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    console.log(codeList.Modal.current);
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        fIDX: $fIDX.current.value,
        fStype: $fBtype.current.value,
        fTITLE: $fTITLE.current.value,
        fCONTENTS: $fCONTENTS.current.value,
        fUSEYN: $fUSEYN.current.value,
      };

      requestUserUpdate(
        updateData,
        $fIDX.current.value.trim() !== "" ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($fBtype.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if ($fTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if ($fCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if ($fUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    console.log("requestUserUpdate");
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/faq/JS_faq_update.php"
          : "/faq/JS_faq_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);

      if (newData) {
        console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED,
            "FaqManageTable",
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "FAQ정보를 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "FAQ정보를 추가 하였습니다.",
                () => {}
              );

          $("#closeFaqEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "FAQ정보 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "FAQ정보 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticFaqEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticFaqEditModal"
      >
        <div className="modal-dialog" style={{ maxWidth: 700 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>FAQ 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeFaqEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$fIDX}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select className="custom-select form-control" ref={$fBtype}>
                    <option value="">미선택</option>
                    <option value="FJA0">서비스문의</option>
                    <option value="FJA1">가맹점문의</option>
                    <option value="FJA2">기타문의</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <textarea className="form-control" rows="3" ref={$fTITLE} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="15"
                    ref={$fCONTENTS}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select className="custom-select form-control" ref={$fUSEYN}>
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary custom-btn-dark"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(FaqManageTable);
