import logo from "./logo.svg";
import "./App.css";
import BuddibleSocket from "./lib/BuddibleSocket";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./lib/ScrollToTop";
import LoginPage from "./main/LoginPage";
import Main from "./main/Main";
import PaymentPlatform from "./paymentPlatform/PaymentPlatform";
import Terms from "./terms/Terms";
import FindPW from "./userPassword/FindPW";
import EditPassword from "./userPassword/EditPassword";

const socket = new BuddibleSocket();

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/EditPassword" component={EditPassword} />
        <Route exact path="/FindPW" component={FindPW} />
        <Route exact path="/LoginPage" component={LoginPage} />
        <Route exact path="/ERP" component={PaymentPlatform} />
        <Route exact path="/Terms" component={Terms} />
        <Route exact path="/" component={Main} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
