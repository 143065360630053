import React, { useEffect, useRef, useState } from "react";
import CodeList from "../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

let codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

export default function CardLawContact() {
  let [loading, setLoading] = useState(false);
  const $controllers = {
    inputCoName: useRef(),
    inputCoType: useRef(),
    inputName: useRef(),
    inputTel: useRef(),
    inputMemo: useRef(),
  };
  const bMapObj = useRef();

  useEffect(() => {
    bMapObj.current.innerHTML = "";
    new window.daum.roughmap.Lander({
      timestamp: "1633569028193",
      key: "27kok",
      mapWidth: "1110",
      mapHeight: "270",
    }).render();
  }, []);
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        inputCoName: $controllers.inputCoName.current.value.trim(),
        inputCoType: $controllers.inputCoType.current.value.trim(),
        inputName: $controllers.inputName.current.value.trim(),
        inputTel: $controllers.inputTel.current.value.trim(),
        inputMemo: $controllers.inputMemo.current.value.trim(),
      };

      requestDataUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controllers.inputCoName.current.value.trim() === "") {
      codeList.Modal.current.alert("상호명을 입력해 주세요.", () => {});
      return false;
    }

    if ($controllers.inputCoType.current.value.trim() === "") {
      codeList.Modal.current.alert("회사 형태를 선택해 주세요.", () => {});
      return false;
    }

    if ($controllers.inputName.current.value.trim() === "") {
      codeList.Modal.current.alert("성명을 입력해 주세요.", () => {});
      return false;
    }

    if ($controllers.inputTel.current.value.trim() === "") {
      codeList.Modal.current.alert("연락처을 입력해 주세요.", () => {});
      return false;
    }

    if ($controllers.inputMemo.current.value.trim() === "") {
      codeList.Modal.current.alert("문의사항을 입력해 주세요.", () => {});
      return false;
    }

    return true;
  };

  const requestDataUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/join_question.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);

      if (newData) {
        console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);

          codeList.Modal.current.alert(
            "가입문의 메일이 전송 완료되었습니다.",
            () => {}
          );
          clearAllData();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "가입문의 메일전송 중 오류가 발생하였습니다.",
            () => {}
          );
        }
      } else {
        setLoading(false);
        codeList.Modal.current.alert(
          "가입문의 메일전송 중 오류가 발생하였습니다.",
          () => {}
        );
      }
    });
  };

  const clearAllData = () => {
    for (let key in $controllers) {
      if ($controllers.hasOwnProperty(key)) {
        if ($controllers[key].current) $controllers[key].current.value = "";
      }
    }
  };
  return (
    <section id="cardLawContact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>가입문의</h2>
          <p>CARDLAW 가입문의</p>
        </div>

        <div className="overflow-hidden">
          <div
            id="daumRoughmapContainer1633569028193"
            className="root_daum_roughmap root_daum_roughmap_landing"
            ref={bMapObj}
          />
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt" />
                <h4>주소지</h4>
                <p>인천광역시 중구 흰바위로59번길8, 219호</p>
              </div>

              <div className="email">
                <i className="bi bi-telephone" />
                <h4>고객센터</h4>
                <p>032-747-0211</p>
              </div>

              <div className="phone">
                <i className="bi bi-clock-history" />
                <h4>영업시간</h4>
                <p>AM 09:00~ PM 06:00 [토, 일, 공휴일 휴무]</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <div role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="상호명"
                    ref={$controllers.inputCoName}
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <select
                    className="form-select"
                    style={{
                      fontSize: "14px",
                      color: "#495057",
                    }}
                    ref={$controllers.inputCoType}
                  >
                    <option value="법무법인(법률사무소)">
                      법무법인(법률사무소)
                    </option>
                    <option value="특허법인(특허법률사무소)">
                      특허법인(특허법률사무소)
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="담당자 이름"
                  ref={$controllers.inputName}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="연락처"
                  ref={$controllers.inputTel}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  placeholder="문의사항을 남겨주세요"
                  ref={$controllers.inputMemo}
                  required
                />
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  onClick={() => {
                    codeList.Modal.current.confirm(
                      "가입문의 메일을 전송 하시겠습니까?",
                      (ret) => {
                        if (ret) actionSave();
                      }
                    );
                  }}
                >
                  문의하기
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
