import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CodeList from "./lib/CodeList";
import CommonUI from "./lib/CommonUI";
let codeList = new CodeList();

ReactDOM.render(
    <>
        <CommonUI
            ref={(e) => {
                codeList.setModal(e);
            }}
        />
        <App />
    </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
