import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import { Link } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function LoginModal({ setIsLogin }) {
  const [loading, setLoading] = useState(false);
  let $userID = useRef();
  let $userPW = useRef();
  let $saveID = useRef();
  /*
  useEffect(() => {
    loginKeyPress();
    window.addEventListener("keypress", loginKeyPress);
    return () => window.removeEventListener("keypress", loginKeyPress);
  });

  const loginKeyPress = (e) => {
    if (e) {
      if (e.key === "Enter") {
        actionLogin();
      }
    }
  };*/

  //login 이벤트 실행
  const actionLogin = (e) => {
    setLoading(true);
    if (checkLoginValidation()) {
      let updateData = {
        userID: util.buddibleEncrypt($userID.current.value),
        userPWD: util.buddibleEncrypt($userPW.current.value),
      };

      requestLogin(updateData);
    } else {
      setLoading(false);
    }
  };

  //아이디 패스워드 입력 검사
  const checkLoginValidation = () => {
    if ($userID.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요", () => {});
      return false;
    }
    if ($userPW.current.value.trim() === "") {
      codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  //로그인정보 전송
  const requestLogin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/login/JS_login.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      $("#loginModalClose").click();
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 1);
          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );

          codeList.Modal.current.alert("로그인 되었습니다.", () => {
            $userID.current.value = "";
            $userPW.current.value = "";
            setIsLogin(true);
            window.location.pathname = "/ERP";
          });

          $("#btnCloseStaticLoginModal").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            newData["MSG"] ||
              "로그인실패, 아이디 또는 비밀번호를 다시 입력해주세요.",
            () => {}
          );
        }
      }
    });
  };
  return (
    <div
      className="modal fade"
      id="loginModal"
      tabIndex="-1"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">로그인</p>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="loginModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="content text-center">
                <div className="logo mb-4 mt-3">
                  <Link to="/">
                    <div
                      style={{
                        background:
                          "url('./logo/CARDLAW_logo(black).svg') 50% 50%/contain no-repeat",
                        height: "50px",
                        width: "100%",
                      }}
                    />
                  </Link>
                </div>
                <input
                  className="form-control main mb-2"
                  type="text"
                  placeholder="아이디"
                  ref={(ref) => {
                    $userID.current = ref;
                  }}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionLogin();
                    }
                  }}
                />
                <input
                  className="form-control main mb-3"
                  type="password"
                  placeholder="비밀번호"
                  ref={(ref) => ($userPW.current = ref)}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionLogin();
                    }
                  }}
                />
                <div className="new-acount">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="saveID"
                    ref={(ref) => ($saveID.current = ref)}
                  />
                  <label className="form-check-label" htmlFor="saveID">
                    아이디저장
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="w-100 d-flex flex-row justify-content-between">
              <Link
                to="FindPW"
                className="btn btn-outline-secondary"
                onClick={() => {
                  $("#loginModalClose").click();
                }}
              >
                비밀번호 찾기
              </Link>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  data-dismiss="modal"
                >
                  닫기
                </button>
                <button
                  type="button"
                  className="btn font-weight-bold text-white"
                  data-dismiss="modal"
                  style={{
                    background: "#ffc451",
                  }}
                  onClick={() => actionLogin()}
                >
                  로그인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
