import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";
import { Link } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function MyInfoManage(props) {
  const { userInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const $inputID = useRef();
  const $inputJoinDate = useRef();

  const $inputName = useRef();
  const $inputTel = useRef();
  const $inputEmail = useRef();
  const $inputEtc = useRef();
  const uCoName = useRef();
  const uCoCEOName = useRef();
  const uCoAddr = useRef();
  const uCoRegNo = useRef();
  const uCoRegFile = useRef();
  const uCoTel = useRef();

  const uCoCEOIDCard = useRef();
  const uCoBankName = useRef();
  const uCoBankNO = useRef();
  const uCoBankBookPic = useRef();

  const uBankTermsYN = useRef();

  useEffect(() => {
    if (userInfo) {
      $inputName.current.value = userInfo.uName;
      $inputTel.current.value = userInfo.uTel;
      $inputEmail.current.value = userInfo.uEmail;
      $inputEtc.current.value = userInfo.uEtc;
      uCoName.current.value = userInfo.uCoName;
      uCoCEOName.current.value = userInfo.uCoCEOName;
      uCoRegNo.current.value = userInfo.uCoRegNo;
      uCoRegFile.current.value = userInfo.uCoRegFile;
      uCoTel.current.value = userInfo.uCoTel;
      uCoCEOIDCard.current.value = userInfo.uCoCEOIDCard;
      uCoBankName.current.value = userInfo.uCoBankName;
      uCoBankNO.current.value = userInfo.uCoBankNO;
      uCoBankBookPic.current.value = userInfo.uCoBankBookPic;
      uBankTermsYN.current.checked = userInfo.uAgreeYN === "Y";
    }
  }, []);

  const _uploadFile = (e, fileRef) => {
    const filedata = e.target.files;
    let formData = new FormData();
    formData.append("fileToUpload", filedata[0]);
    let realFileName = filedata[0]["name"];

    axios
      .post(
        "//cardlaw.co.kr/backside/requestRegist/JS_requestRegist_upload_file.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data["ret"] === true) {
          const changedFileName = response.data["file"];
          fileRef.current.value = `${realFileName}?${changedFileName}`;
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
            () => {}
          );
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };

  const _checkValidation = () => {
    if (uCoRegNo.current.value.trim() === "") {
      return uCoRegNo;
    }
    if (uCoRegFile.current.value.trim() === "") {
      return uCoRegFile;
    }
    if (uCoCEOIDCard.current.value.trim() === "") {
      return uCoCEOIDCard;
    }
    if (uCoBankName.current.value.trim() === "") {
      return uCoBankName;
    }
    if (uCoBankNO.current.value.trim() === "") {
      return uCoBankNO;
    }
    if (uCoBankBookPic.current.value.trim() === "") {
      return uCoBankBookPic;
    }
    if (!uBankTermsYN.current.checked) {
      return uBankTermsYN;
    }

    return false;
  };

  const _requestUserAgree = () => {
    const emptyRef = _checkValidation();

    if (emptyRef) {
      codeList.Modal.current.alert("필수입력정보가 누락되었습니다.");
      emptyRef.current.focus();
      return;
    }
    _saveUserInfo("Y");
  };

  const _checkChangedPrimaryInfo = () => {
    if (uCoRegNo.current.value !== userInfo.uCoRegNo) {
      return true;
    }
    if (uCoRegFile.current.value !== userInfo.uCoRegFile) {
      return true;
    }
    if (uCoCEOIDCard.current.value !== userInfo.uCoCEOIDCard) {
      return true;
    }
    if (uCoBankName.current.value !== userInfo.uCoBankName) {
      return true;
    }
    if (uCoBankNO.current.value !== userInfo.uCoBankNO) {
      return true;
    }
    if (uCoBankBookPic.current.value !== userInfo.uCoBankBookPic) {
      return true;
    }

    return false;
  };

  const _saveUserInfo = (isRequestAgree) => {
    if (isRequestAgree === "N" && _checkChangedPrimaryInfo()) {
      codeList.Modal.current.alert("필수 입력정보 변경시 재승인이 필요합니다.");
      return;
    }
    setIsLoading(true);
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/account/JS_account_request_agree.php",
      msgID: msgID,
      data: {},
      uID: util.buddibleEncrypt($inputID.current.value),
      uName: $inputName.current.value,
      uTel: $inputTel.current.value,
      uEmail: $inputEmail.current.value,
      uJoinDate: $inputJoinDate.current.value,
      uEtc: $inputEtc.current.value,
      uCoName: uCoName.current.value,
      uCoCEOName: uCoCEOName.current.value,
      uCoAddr: uCoAddr.current.value,
      uCoRegNo: uCoRegNo.current.value,
      uCoRegFile: uCoRegFile.current.value,
      uCoTel: uCoTel.current.value,
      uCoCEOIDCard: uCoCEOIDCard.current.value,
      uCoBankName: uCoBankName.current.value,
      uCoBankNO: uCoBankNO.current.value,
      uCoBankBookPic: uCoBankBookPic.current.value,
      isRequestAgree: isRequestAgree,
      uAgreeYN: "R",
      uWorkYN: "Y",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          codeList.Modal.current.alert(
            isRequestAgree === "Y"
              ? "가입승인 요청이 완료되었습니다."
              : "개인정보가 변경되었습니다.",
            () => {
              window.location.reload();
            }
          );
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="content">
        <div className="container-florder_uid p-0">
          <div className="d-sm-flex flex-row align-items-end justify-content-start mb-4">
            <h1 className="h3 mb-0 text-gray-800">내정보 관리</h1>

            {userInfo.uAgreeYN !== "Y" ? (
              <p className="font-weight-bold ml-3 mb-0">
                <span className="text-danger">※ 결제 활성화 승인전</span> : 결제
                활성화를 위하여 아래의 필수 입력사항과 파일을 첨부하시고
                가입승인 신청버튼을 눌러주세요.
              </p>
            ) : (
              <p className="font-weight-bold ml-3 mb-0">
                <span className="text-primary">※ 결제 활성화 됨</span> : 결제
                활성 승인이 완료되어 정상 사용 가능합니다. 결제요청서 등록에서
                새로운 결제 요청서를 작성해 보세요.
              </p>
            )}
          </div>

          <div className="card d-inline-block" style={{ minWidth: 600 }}>
            <div className="row p-4">
              <div className="col-12">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">아이디</label>
                  <div className="col-sm-8">
                    <input
                      type="account"
                      className="form-control"
                      placeholder="ID"
                      ref={$inputID}
                      readOnly={true}
                      value={util.buddibleDecrypt(userInfo.uID)}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">사용자명</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="User Name"
                      ref={$inputName}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">연락처</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Tel Number"
                      ref={$inputTel}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">Email</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      ref={$inputEmail}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">가입일</label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="date"
                      disabled={false}
                      ref={$inputJoinDate}
                      readOnly={true}
                      value={util.getDateToFormat(
                        new Date(userInfo.uJoinDate),
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                </div>

                <div className="pb-3 mb-3 row border-bottom">
                  <label className="col-form-label col-sm-4">비고</label>
                  <div className="col-sm-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      ref={$inputEtc}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">회사명</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="회사명"
                      ref={uCoName}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">대표자명</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="대표자명"
                      ref={uCoCEOName}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">회사주소</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="회사주소"
                      ref={uCoAddr}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    사업자등록번호
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="사업자등록번호"
                      ref={uCoRegNo}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    사업자등록증{" "}
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                  </label>
                  <div className="col-sm-8">
                    <input type="text" ref={uCoRegFile} className="d-none" />
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => _uploadFile(e, uCoRegFile)}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        파일첨부
                      </label>
                    </div>
                    {uCoRegFile.current && uCoRegFile.current.value && (
                      <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                        <button
                          className="btn btn-sm btn-danger mr-2"
                          onClick={() => {
                            uCoRegFile.current.value = "";
                            setIsNeedUpdate(!isNeedUpdate);
                          }}
                        >
                          삭제
                        </button>
                        <span className="font-weight-bold">
                          {uCoRegFile.current.value?.split("?")[0]}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="pb-2 mb-3 row border-bottom">
                  <label className="col-form-label col-sm-4">회사연락처</label>
                  <div className="col-sm-8">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="회사연락처"
                      ref={uCoTel}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    대표자 신분증
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    <br />
                    (정산계좌와 일치)
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="d-none" ref={uCoCEOIDCard} />

                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => _uploadFile(e, uCoCEOIDCard)}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        파일첨부
                      </label>
                    </div>
                    {uCoCEOIDCard.current && uCoCEOIDCard.current.value && (
                      <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                        <button
                          className="btn btn-sm btn-danger mr-2"
                          onClick={() => {
                            uCoCEOIDCard.current.value = "";
                            setIsNeedUpdate(!isNeedUpdate);
                          }}
                        >
                          삭제
                        </button>
                        <span className="font-weight-bold">
                          {uCoCEOIDCard.current.value?.split("?")[0]}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    정산은행 은행명
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="은행명"
                      ref={uCoBankName}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    정산은행 계좌번호
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="계좌번호"
                      ref={uCoBankNO}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-4">
                    통장사본 이미지
                    <i style={{ color: "red", fontWeight: 700 }}>*</i>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="d-none"
                      ref={uCoBankBookPic}
                    />

                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => _uploadFile(e, uCoBankBookPic)}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        파일첨부
                      </label>
                    </div>
                    {uCoBankBookPic.current && uCoBankBookPic.current.value && (
                      <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                        <button
                          className="btn btn-sm btn-danger mr-2"
                          onClick={() => {
                            uCoBankBookPic.current.value = "";
                            setIsNeedUpdate(!isNeedUpdate);
                          }}
                        >
                          삭제
                        </button>
                        <span className="font-weight-bold">
                          {uCoBankBookPic.current.value?.split("?")[0]}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-2 row">
                  <div className="col-sm-12">
                    <label className="font-weight-bold mb-0">
                      <input
                        type="checkbox"
                        className="mr-2"
                        ref={uBankTermsYN}
                        // defaultChecked={userInfo.uAgreeYN === "Y"}
                      />
                      카드로 금융거래 이용약관 동의(필수)
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-right bg-white">
              <div className="w-100 d-flex flex-row justify-content-between">
                <Link to="/EditPassword" className="btn btn-outline-secondary">
                  비밀번호 변경
                </Link>
                <div>
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={() => _requestUserAgree()}
                    disabled={userInfo.uAgreeYN === "Y"}
                  >
                    {userInfo.uAgreeYN === "Y" ? "승인 완료" : "승인 신청"}
                    {isLoading && (
                      <>
                        <i className="fa fa-spinner mb-0 ml-2 rotate-infinite" />
                      </>
                    )}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => _saveUserInfo("N")}
                  >
                    <i className="fa fa-check mr-2" />
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
