import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import axios from "axios";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _validationMSG = {
  uID: "아이디를 입력해주세요",
  uPWD: "비밀번호를 입력해주세요",
  uType: "자격구분을 선택해주세요.",
  uName: "사용자명을 입력해주세요.",
  uCoTel: "연락처를 입력해주세요",
  uEmail: "이메일을 입력해주세요.",
  uTermsYN: "약관에 동의해주세요.",
};

export default function RegistModal() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isPWCheck, setIsPWCheck] = useState(false);
  const uPWCheck = useRef();
  const controller = {
    uID: useRef(),
    uPWD: useRef(),
    uType: useRef(),
    uName: useRef(),
    uCoTel: useRef(),
    uEmail: useRef(),
    uTermsYN: useRef(),
  };
  const controllerTerms = {
    useTerms: useRef(),
    policy: useRef(),
  };

  const _checkUserID = () => {
    const value = controller.uID.current.value;
    if (value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요.");
      return;
    }
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/requestRegist/JS_requestRegist_check_ID.php",
      msgID: msgID,
      data: {},
      uID: value,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (Number(newData["cnt"]) > 0) {
          codeList.Modal.current.alert("중복된 아이디입니다.");
          return;
        } else {
          codeList.Modal.current.alert("사용가능한 아이디입니다.");
          setIsCheck(true);
        }
      }
    });
  };

  const _checkUserPW = () => {
    const pw1 = controller.uPWD.current.value;
    const pw2 = uPWCheck.current.value;
    setIsPWCheck(pw1 === pw2);
  };

  const _checkAgreeTerms = () => {
    if (
      controllerTerms.useTerms.current.checked &&
      controllerTerms.policy.current.checked
    ) {
      controller.uTermsYN.current.checked = true;
    } else {
      controller.uTermsYN.current.checked = false;
    }
  };

  const _checkValidation = () => {
    if (controller.uID.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uID);
      return true;
    }
    if (controller.uPWD.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uPWD);
      return true;
    }
    if (controller.uType.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uType);
      return true;
    }
    if (controller.uName.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uName);
      return true;
    }
    if (controller.uCoTel.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uCoTel);
      return true;
    }
    if (controller.uEmail.current.value.trim() === "") {
      codeList.Modal.current.alert(_validationMSG.uEmail);
      return true;
    }
    if (!controller.uTermsYN.current.checked) {
      codeList.Modal.current.alert(_validationMSG.uTermsYN);
      return true;
    }

    if (!isCheck) {
      codeList.Modal.current.alert("아이디 확인을 진행해주세요");
      return true;
    }

    if (!isPWCheck) {
      codeList.Modal.current.alert("비밀번호 확인을 진행해주세요");
      return true;
    }

    return false;
  };

  const _requestRegist = () => {
    if (_checkValidation()) return;
    setIsLoading(true);
    const updateData = {
      uID: controller.uID.current.value,
      uPWD: util.buddibleEncrypt(controller.uPWD.current.value),
      uType: controller.uType.current.value,
      uName: controller.uName.current.value,
      uCoTel: controller.uCoTel.current.value,
      uEmail: controller.uEmail.current.value,
      uTermsYN: controller.uTermsYN.current.checked ? "Y" : "N",
    };
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/account/JS_account_join.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert(
            "회원가입이 완료되었습니다.\n 로그인 후 이용 가능합니다.",
            () => {
              $("#registModalClose").click();
            }
          );
        } else {
          codeList.Modal.current.alert(
            newData["MSG"] || "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
        setIsLoading(false);
      }
    });
  };

  const _clear = () => {
    for (const key in controller) {
      if (!controller[key].current) continue;
      if (controller[key].current.type !== "checkbox") {
        controller[key].current.value = "";
      } else {
        controller[key].current.checked = false;
      }
    }
    for (const key in controllerTerms) {
      controllerTerms[key].current.checked = false;
    }
    uPWCheck.current.value = "";
    setIsCheck(false);
    setIsPWCheck(false);
    setIsLoading(false);
  };

  return (
    <div
      className="modal fade show"
      id="registModal"
      tabIndex="-1"
      aria-labelledby="registModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div
            className="p-3 d-flex flex-row justify-content-between align-items-center"
            style={{ background: "#000000" }}
          >
            <img src="logo/CARDLAW_logo_horizontal(white).png" alt="logo" />

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="registModalClose"
              onClick={() => _clear()}
            >
              <span className="text-white" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="container">
            <p className="font-weight-bold text-center pt-3">
              카드로 회원 가입으로 간편하게 카드결제 받으세요
            </p>

            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                아이디
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9 d-flex flex-row justify-content-between">
                <div className="flex-fill mr-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="아이디를 입력해주세요"
                    ref={controller.uID}
                    disabled={isCheck}
                  />
                </div>
                <button
                  className="btn_1"
                  disabled={isCheck}
                  onClick={() => {
                    _checkUserID();
                  }}
                >
                  아이디 확인
                </button>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                비밀번호
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <input
                  type="password"
                  className="form-control"
                  placeholder="비밀번호를 입력해주세요"
                  disabled={isPWCheck}
                  ref={controller.uPWD}
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                비밀번호 확인
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <div className=" d-flex flex-row justify-content-between">
                  <div className="flex-fill mr-2">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="비밀번호 확인"
                      ref={uPWCheck}
                      disabled={isPWCheck}
                    />
                  </div>
                  <button
                    className="btn_1"
                    disabled={isPWCheck}
                    onClick={() => {
                      _checkUserPW();
                    }}
                  >
                    비밀번호 입력 확인
                  </button>
                </div>
                <p
                  className={
                    isPWCheck
                      ? "font-weight-bold text-primary mb-0"
                      : "font-weight-bold text-danger mb-0"
                  }
                >
                  ※
                  {isPWCheck
                    ? "비밀번호가 일치합니다."
                    : "비밀번호가 일치하지 않습니다."}
                </p>
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                자격구분 <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <select
                  className="custom-select form-control"
                  ref={controller.uType}
                >
                  <option value="">선택</option>
                  <option value="A">변호사</option>
                  <option value="B">변리사</option>
                  <option value="C">법무사</option>
                  <option value="D">노무사</option>
                </select>
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                사용자명 <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={controller.uName}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                연락처 <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={controller.uCoTel}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                이메일 <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={controller.uEmail}
                  // ref={$controllers.comp_nm}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-center">
                약관동의 <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9 pt-2">
                <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2 font-weight-bold">
                  <label className="container_check m-0 p-0 mr-2">
                    <input
                      type="checkbox"
                      className="position-static"
                      ref={controller.uTermsYN}
                      onChange={(e) => {
                        let val = e.target.checked;
                        for (let key in controllerTerms) {
                          if (controllerTerms[key].current) {
                            controllerTerms[key].current.checked = val;
                          }
                        }
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                  전체동의
                </div>

                <div className="d-flex flex-row flex-wrap">
                  <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                    <label className="container_check m-0 p-0 mr-2">
                      <input
                        type="checkbox"
                        className="position-static"
                        ref={controllerTerms.useTerms}
                        onChange={() => _checkAgreeTerms()}
                      />
                      <span className="checkmark" />
                    </label>
                    <Link
                      to="/Terms#termsOfService"
                      target="_blank"
                      className="align-middle text-primary"
                    >
                      CARDLAW 이용약관 동의
                    </Link>
                  </div>
                  <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                    <label className="container_check m-0 p-0 mr-2">
                      <input
                        type="checkbox"
                        className="position-static"
                        ref={controllerTerms.policy}
                        onChange={() => _checkAgreeTerms()}
                      />
                      <span className="checkmark" />
                    </label>
                    <Link
                      to="/Terms#privacyPolicy"
                      target="_blank"
                      className="align-middle text-primary"
                    >
                      개인정보처리방침
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn_1 d-inline-flex flex-row align-items-center"
              onClick={() => _requestRegist()}
              disabled={isLoading}
            >
              <span>회원가입</span>
              {isLoading && (
                <i className="fa-solid fa-circle-notch h5 mb-0 ml-2 rotate" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
