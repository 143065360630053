import React, { useState, useRef, useEffect } from "react";
import MainNav from "./MainNav";
import MainFooter from "./MainFooter";
import "./main.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  let $userID = useRef();
  let $userPW = useRef();
  let $saveID = useRef();
  /*
    useEffect(() => {
      loginKeyPress();
      window.addEventListener("keypress", loginKeyPress);
      return () => window.removeEventListener("keypress", loginKeyPress);
    });

    const loginKeyPress = (e) => {
      if (e) {
        if (e.key === "Enter") {
          actionLogin();
        }
      }
    };*/

  //login 이벤트 실행
  const actionLogin = (e) => {
    setLoading(true);
    if (checkLoginValidation()) {
      let updateData = {
        userID: util.buddibleEncrypt($userID.current.value),
        userPWD: util.buddibleEncrypt($userPW.current.value),
      };

      requestLogin(updateData);
    } else {
      setLoading(false);
    }
  };

  //아이디 패스워드 입력 검사
  const checkLoginValidation = () => {
    if ($userID.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요", () => {});
      return false;
    }
    if ($userPW.current.value.trim() === "") {
      codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  //로그인정보 전송
  const requestLogin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/login/JS_login.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      $("#loginModalClose").click();
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 1);
          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );

          codeList.Modal.current.alert("로그인 되었습니다.", () => {
            $userID.current.value = "";
            $userPW.current.value = "";
            window.location.pathname = "/";
          });

          $("#btnCloseStaticLoginModal").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "로그인실패, 아이디 또는 비밀번호를 다시 입력해주세요.",
            () => {}
          );
        }
      }
    });
  };
  return (
    <>
      <div
        id="sample1"
        className=" d-flex flex-column h-100"
        style={{ minHeight: "100vh" }}
      >
        <MainNav />
        <section
          id="hero"
          className="d-flex align-items-center justify-content-center vh-100"
          style={{
            background: `url("assets/img/hero-bg.jpg") top /cover no-repeat`,
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ minWidth: 400 }}>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="content text-center">
                    <div className="logo mb-4 mt-3">
                      <Link to="/">
                        <div
                          style={{
                            background:
                              "url('./logo/CARDLAW_logo(black).svg') 50% 50%/contain no-repeat",
                            height: "50px",
                            width: "100%",
                          }}
                        />
                      </Link>
                    </div>
                    <input
                      className="form-control main mb-2"
                      type="text"
                      placeholder="아이디"
                      ref={(ref) => {
                        $userID.current = ref;
                      }}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          actionLogin();
                        }
                      }}
                    />
                    <input
                      className="form-control main mb-3"
                      type="password"
                      placeholder="비밀번호"
                      ref={(ref) => ($userPW.current = ref)}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          actionLogin();
                        }
                      }}
                    />
                    <div className="new-acount">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="saveID"
                        ref={(ref) => ($saveID.current = ref)}
                      />
                      <label className="form-check-label" htmlFor="saveID">
                        아이디저장
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100 d-flex flex-row justify-content-between">
                  <Link to="FindPW" className="btn btn-outline-secondary">
                    비밀번호 찾기
                  </Link>
                  <div>
                    <button
                      type="button"
                      className="btn font-weight-bold text-white"
                      data-dismiss="modal"
                      style={{
                        background: "#ffc451",
                      }}
                      onClick={() => actionLogin()}
                    >
                      로그인
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <MainFooter />
      </div>
    </>
  );
}
