import React, { useEffect, useState } from "react";
import CodeList from "../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function CommonHeader() {
  const [userInfo, setUserInfo] = useState({});

  let SlidNav = () => {
    let classList = [...document.querySelector("#sidebar").classList];
    let existence = classList.findIndex(
      (data) => data === "customCollapsedSlideBar"
    );
    if (existence === -1) {
      document.querySelector("#sidebar").className =
        "sidebar customCollapsedSlideBar";
    } else {
      document.querySelector("#sidebar").className = "sidebar";
    }
  };

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "CommonSlideNavbar",
      (b, n) => {
        if (n.ret) {
          setUserInfo({ ...n["returnData"][0] });
        }
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "CommonHeader"
      );
    };
  }, []);

  const logout = () => {
    codeList.Modal.current.confirm("종료 하시겠습니까?", (ret) => {
      if (ret) {
        sessionStorage.clear();
        window.location.replace("/");
      }
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top">
        <button id="sidebarToggleTop" className="btn rounded-circle mr-3">
          <i className="fa fa-bars"></i>
        </button>

        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow">
            {/*  <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Right-aligned menu
          </button>*/}

            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="img-profile rounded-circle "
                src="/images/user_pic-50x50.png"
                style={{ maxWidth: "60px" }}
              />
              <span className="ml-2 mr-2 d-none d-lg-inline text-white ">
                {userInfo.uName}
              </span>
              <i className="fa fa-cog mr-2" />
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <button className="dropdown-item" type="button" onClick={logout}>
                <i className="fa fa-sign-out-alt mr-1" />
                로그아웃
              </button>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default CommonHeader;
