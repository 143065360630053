import React, { useState, useEffect } from "react";
import LoginModal from "./LoginModal";
import RegistModal from "./RegistModal";

export default function MainNav(props) {
  const { isNotShowGNB } = props;
  const [activeMenu, setActiveMenu] = useState("");
  const [menuList, setMenuList] = useState([
    { id: "hero", name: "서비스소개" },
    { id: "cardLawPartner", name: "가맹점현황" },
    { id: "cardLawUser", name: "파트너소개" },
    { id: "cardLawCharge", name: "수수료안내" },
    { id: "cardLawContact", name: "가입문의" },
    { id: "faq", name: "도움말" },
  ]);
  //{ id: "introduceCard", name: "제휴카드소개" },
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (sessionStorage.hasOwnProperty("loginInfo")) {
      setIsLogin(true);
    }
    try {
      window.addEventListener("scroll", ActiveMainMenu);
      return () => {
        window.removeEventListener("scroll", ActiveMainMenu);
      };
    } catch (e) {}
  });

  const ActiveMainMenu = () => {
    menuList.forEach((menu) => {
      if (window.location.hash === "faq" && menu.id === "faq") {
        setActiveMenu(menu.id);
        return null;
      } else {
        try {
          let sy = window.scrollY;
          let py = document.getElementById(`${menu.id}`).offsetTop;
          if (py - 150 <= sy && py + 150 >= sy) {
            setActiveMenu(menu.id);
          }
        } catch (e) {}
      }
    });
  };

  const ActiveMobileNav = () => {
    document.getElementById("navbar").classList.toggle("navbar-mobile");
    document.getElementById("mobile-nav-toggle").classList.toggle("bi-list");
    document.getElementById("mobile-nav-toggle").classList.toggle("bi-x");
  };

  return (
    <>
      <LoginModal setIsLogin={setIsLogin} />
      <RegistModal />
      <div
        id="header"
        className="fixed-top"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="container d-flex align-items-center justify-content-lg-between">
          <h1 className="logo me-auto me-lg-0">
            <a href="/#hero">
              <img src="logo/CARDLAW_logo_horizontal(white).png" />
            </a>
          </h1>
          {!isNotShowGNB && (
            <>
              <nav id="navbar" className="navbar order-last order-lg-0 ">
                <ul className="mr-4">
                  {menuList.map((menu) => {
                    return (
                      <li key={menu.id}>
                        <a
                          className={`nav-link scrollto ${
                            activeMenu === menu.id ? "active" : ""
                          }`}
                          href={`/#${menu.id}`}
                          onClick={() => {
                            let val =
                              document.getElementById("navbar").classList.value;
                            if (val.indexOf("navbar-mobile") !== -1) {
                              ActiveMobileNav();
                            }
                          }}
                        >
                          {menu.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>

                <i
                  id="mobile-nav-toggle"
                  className="bi bi-list mobile-nav-toggle"
                  onClick={() => {
                    ActiveMobileNav();
                  }}
                />
              </nav>
              {isLogin ? (
                <div className="dropdown">
                  <button
                    className="btn text-center get-started-btn scrollto "
                    type="button"
                    id="myPage"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    마이페이지
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="myPage"
                  >
                    <a className="dropdown-item pt-2" href="/ERP">
                      <i className="bi bi-bar-chart-line-fill" /> ERP 바로가기
                    </a>
                    <a className="dropdown-item pt-2" href="/#faq">
                      <i className="bi bi-info-circle" />
                      도움말
                    </a>
                    <div className="dropdown-divider" />
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={() => {
                        sessionStorage.clear();
                      }}
                    >
                      <i className="bi bi-box-arrow-right" /> logout
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <span
                    className="btn text-center get-started-btn scrollto"
                    data-toggle="modal"
                    data-target="#loginModal"
                  >
                    로그인
                  </span>
                  <span
                    className="btn  text-center get-started-btn scrollto ml-2"
                    data-toggle="modal"
                    data-target="#registModal"
                  >
                    회원가입
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
