import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import BranchManageTable from "./BranchPPManageTable";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function BranchPPManageEdit(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState("defaultLogo.png");

  const $controller = {
    bIDX: useRef(),
    bLogo: useRef(),
    bBranchName: useRef(),
    bBranchTYPE: useRef(),
    bUserName: useRef(),
    bCEOName: useRef(),
    bTel: useRef(),
    bEmail: useRef(),
    bMemo: useRef(),
    bShowYN: useRef(),
    bOrder: useRef(),
    bService: useRef(),
    bHomepage: useRef(),
  };

  const dataChange = (e, params) => {
    console.log(userData, params);
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      for (let key in $controller) {
        if (
          $controller.hasOwnProperty(key) &&
          userData.currentData.hasOwnProperty(key)
        ) {
          if ($controller[key].current)
            $controller[key].current.value = userData.currentData[key];
        }
      }
      setLogoUrl(userData.currentData["bLogo"]);
    } else {
      for (let key in $controller) {
        if ($controller.hasOwnProperty(key)) {
          if ($controller[key].current) $controller[key].current.value = "";
        }
      }
      setLogoUrl("defaultLogo.png");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL,
      "BranchPPManageEdit",
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      console.log("hi");
      socket.removeLocalEventListener(
        MsgIDList.EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL,
        "BranchPPManageEdit"
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    if (checkValidation()) {
      let updateData = {};
      for (let key in $controller) {
        if ($controller.hasOwnProperty(key)) {
          if ($controller[key].current)
            updateData[key] = $controller[key].current.value;
        }
      }
      requestUserUpdate(
        updateData,
        $controller.bIDX.current.value !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const logoChanged = (e) => {
    requestPhotoUpload(e.target.files);
  };

  function requestPhotoUpload(filedata, callback) {
    let formData = new FormData();
    formData.append("fileToUpload", filedata[0]);

    axios
      .post(
        "//cardlaw.co.kr/backside/branch_pp/JS_branch_pp_upload_photo_save.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("axios handleData", response.data);
        if (response.data["ret"] === true) {
          setLogoUrl(response.data["file"]);
          $controller.bLogo.current.value = response.data["file"];
        } else {
          codeList.Modal.current.alert(
            "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
            () => {}
          );
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  }

  const checkValidation = () => {
    if ($controller.bBranchTYPE.current.value.trim() === "") {
      codeList.Modal.current.alert("타입을 선택해 주세요.", () => {});
      return false;
    }
    if ($controller.bUserName.current.value.trim() === "") {
      console.log($controller.bUserName.current.value);
      codeList.Modal.current.alert("성명은 필수 입력사항 입니다.", () => {});
      return false;
    }
    if ($controller.bShowYN.current.value.trim() === "") {
      codeList.Modal.current.alert("노출 여부를 선택해 주세요.", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/branch_pp/JS_branch_pp_update.php"
          : "/branch_pp/JS_branch_pp_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          $("#BranchPPEditModalClose").click();

          if (TYPE === EDIT_TYPE.UPDATE) {
            codeList.Modal.current.alert(
              "정보 수정이 완료되었습니다.",
              () => {}
            );
          } else {
            codeList.Modal.current.alert(
              "정보 저장이 완료되었습니다.",
              () => {}
            );
          }

          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_BRANCH_PP_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_BRANCH_PP_MANAGE_DATA_INSERTED,
            "UserManagementEdit",
            newData,
            () => {}
          );

          for (let key in $controller) {
            if ($controller[key].current) {
              $controller[key].current.value = "";
            }
          }
          setLogoUrl("defaultLogo.png");
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "저장 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "저장 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  return (
    <>
      <div
        className={"modal fade"}
        id="branchPPEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog" style={{ maxWidth: 1000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>법률대리인 소개 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="BranchPPEditModalClose"
                onClick={() => {
                  for (let key in $controller) {
                    if ($controller[key].current) {
                      $controller[key].current.value = "";
                    }
                  }
                  setLogoUrl("defaultLogo.png");
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      관리번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="account"
                        className="form-control"
                        ref={$controller.bIDX}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <fieldset className="mb-3">
                    <div className="row">
                      <label className="col-form-label col-sm-3 text-sm-end pt-sm-0">
                        타입
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control form-select"
                          ref={$controller.bBranchTYPE}
                        >
                          <option value="">미선택</option>
                          <option value="0">변호사</option>
                          <option value="1">변리사</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      성명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bUserName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      출신
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bCEOName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      전문분야
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bService}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      소속로펌
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bBranchName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      전화번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        ref={$controller.bTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      E-mail
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        ref={$controller.bEmail}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      소개글
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        rows="5"
                        ref={$controller.bMemo}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      사진
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div
                          style={{
                            width: "100%",
                            height: "225px",
                            backgroundImage: `url('//cardlaw.co.kr/uploadFiles/logo/${logoUrl}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        />

                        <input
                          type="text"
                          className="form-control"
                          ref={$controller.bLogo}
                          disabled={true}
                        />
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={logoChanged.bind(this)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            권장사이즈 255x255 픽셀
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      홈페이지 및 SNS
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bHomepage}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      노출여부
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        ref={$controller.bShowYN}
                      >
                        <option value="">미선택</option>
                        <option value="Y">표시</option>
                        <option value="N">미표시</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      노출순서
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        ref={$controller.bOrder}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={() => {
                  for (let key in $controller) {
                    if ($controller[key].current) {
                      $controller[key].current.value = "";
                    }
                  }
                  setLogoUrl("defaultLogo.png");
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary custom-btn-dark"
                onClick={(e) => actionSave(e)}
              >
                <i className="fa fa-check mr-2" />
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(BranchPPManageEdit);
