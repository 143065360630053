import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const _mClassName = "UserManagementEdit";

function UserManagementEdit(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const $inputID = useRef();
  const $inputPWD = useRef();
  const $inputPWDRe = useRef();
  const $inputName = useRef();
  const $inputTel = useRef();
  const $inputEmail = useRef();
  const $inputEtc = useRef();
  const $inputLevel = useRef();
  const $inputJoinDate = useRef();
  const $inputWorkYN = useRef();

  const uCoName = useRef();
  const uCoCEOName = useRef();
  const uCoAddr = useRef();
  const uCoRegNo = useRef();
  const uCoTel = useRef();
  // const uPGIDSamsung = useRef();
  // const uPGIDNoneSamsung = useRef();
  // const uPGKeySamsung = useRef();
  // const uPGKeyNoneSamsung = useRef();
  const uAgreeYN = useRef();

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $inputID.current.value = util.buddibleDecrypt(userData.currentData.uID);
      $inputID.current.disabled = true;
      $inputName.current.value = userData.currentData.uName;
      $inputTel.current.value = userData.currentData.uTel;
      $inputEmail.current.value = userData.currentData.uEmail;
      $inputEtc.current.value = userData.currentData.uEtc;
      $inputLevel.current.value = userData.currentData.uLevel;
      $inputWorkYN.current.value = userData.currentData.uWorkYN;
      $inputJoinDate.current.value = userData.currentData.uJoinDate;
      uCoName.current.value = userData.currentData.uCoName;
      uCoCEOName.current.value = userData.currentData.uCoCEOName;
      uCoAddr.current.value = userData.currentData.uCoAddr;
      uCoRegNo.current.value = userData.currentData.uCoRegNo;
      uCoTel.current.value = userData.currentData.uCoTel;
      // uPGIDSamsung.current.value = userData.currentData.uPGIDSamsung;
      // uPGIDNoneSamsung.current.value = userData.currentData.uPGIDNoneSamsung;
      // uPGKeySamsung.current.value = userData.currentData.uPGKeySamsung;
      // uPGKeyNoneSamsung.current.value = userData.currentData.uPGKeyNoneSamsung;
      uAgreeYN.current.value = userData.currentData.uAgreeYN;
    } else {
      $inputID.current.value = "";
      $inputID.current.disabled = false;
      $inputName.current.value = "";
      $inputName.current.value = "";
      $inputTel.current.value = "";
      $inputEmail.current.value = "";
      $inputEtc.current.value = "";
      $inputLevel.current.value = "";
      $inputWorkYN.current.value = "";
      $inputJoinDate.current.value = "";
      $inputPWD.current.value = "";
      $inputPWDRe.current.value = "";

      uCoName.current.value = "";
      uCoCEOName.current.value = "";
      uCoAddr.current.value = "";
      uCoRegNo.current.value = "";
      uCoTel.current.value = "";
      // uPGIDSamsung.current.value = "";
      // uPGIDNoneSamsung.current.value = "";
      // uPGKeySamsung.current.value = "";
      // uPGKeyNoneSamsung.current.value = "";
      uAgreeYN.current.value = "";
    }
  }, [userData]);

  useEffect(() => {
    let isIE = window.ActiveXObject || "ActiveXObject" in window;
    if (isIE) {
      $(".modal").removeClass("fade");
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        uID: util.buddibleEncrypt($inputID.current.value),
        uName: $inputName.current.value,
        uTel: $inputTel.current.value,
        uEmail: $inputEmail.current.value,
        uJoinDate: $inputJoinDate.current.value,
        uEtc: $inputEtc.current.value,
        uWorkYN: $inputWorkYN.current.value,
        uCoName: uCoName.current.value,
        uCoCEOName: uCoCEOName.current.value,
        uCoAddr: uCoAddr.current.value,
        uCoRegNo: uCoRegNo.current.value,
        uCoTel: uCoTel.current.value,
        // uPGIDSamsung: uPGIDSamsung.current.value,
        // uPGIDNoneSamsung: uPGIDNoneSamsung.current.value,
        // uPGKeySamsung: uPGKeySamsung.current.value,
        // uPGKeyNoneSamsung: uPGKeyNoneSamsung.current.value,
        uAgreeYN: uAgreeYN.current.value,
        uLevel: $inputLevel.current.value,
      };
      if ($inputPWD.current.value.trim() !== "") {
        updateData["uPWD"] = util.buddibleEncrypt(
          $inputPWD.current.value.trim()
        );
      }

      requestUserUpdate(
        updateData,
        $inputID.current.disabled ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($inputID.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 아이디를 입력해주세요", () => {});
      return false;
    }

    if ($inputID.current.disabled === false) {
      if (
        $inputPWD.current.value.trim() === "" ||
        $inputPWDRe.current.value.trim() === ""
      ) {
        codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
        return false;
      } else {
        if (
          $inputPWD.current.value.trim() !== $inputPWDRe.current.value.trim()
        ) {
          codeList.Modal.current.alert(
            "비밀번호가 다릅니다. 비밀번호 확인을 다시 입력해주세요",
            () => {}
          );
          return false;
        }
      }
    }
    if ($inputPWD.current.value.trim() !== "") {
      if ($inputPWDRe.current.value.trim() !== "") {
        if (
          $inputPWD.current.value.trim() !== $inputPWDRe.current.value.trim()
        ) {
          codeList.Modal.current.alert(
            "비밀번호가 다릅니다. 비밀번호 확인을 다시 입력해주세요",
            () => {}
          );
          return false;
        }
      } else {
        codeList.Modal.current.alert(
          "비밀번호 재확인을 입력해주세요",
          () => {}
        );
        return false;
      }
    }
    if ($inputLevel.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 권한을 선택 해주세요", () => {});
      return false;
    }
    if ($inputJoinDate.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자 가입일을 선택 해주세요", () => {});
      return false;
    }
    if ($inputWorkYN.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디 사용여부를 선택 해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/account/JS_account_update.php"
          : "/account/JS_account_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_USER_MANAGE_USER_UPDATED
              : MsgIDList.EVENT_USER_MANAGE_USER_INSERTED,
            _mClassName,
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "사용자 정보를 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "사용자 정보를 추가 하였습니다.",
                () => {}
              );

          $("#btnCloseUserEditModal").click();
        } else {
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `사용자 정보 업데이트 중 오류가 발생하였습니다.\n${newData.MSG}`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `사용자 정보 추가 중 오류가 발생하였습니다.\n${newData.MSG}`,
                () => {}
              );
        }
        setLoading(false);
      }
    });
  };

  const makeShortenLink = () => {
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      { long_url: `https://www.cardlaw.co.kr/UserPayment?link=${msgID}` },
      msgID,
      (beforeData, newData) => {
        console.log(newData);
      }
    );
  };

  return (
    <>
      <div
        className={"modal fade"}
        id="staticUserManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog" style={{ maxWidth: 1000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>계정 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="btnCloseUserEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">아이디</label>
                    <div className="col-sm-8">
                      <input
                        type="account"
                        className="form-control"
                        placeholder="ID"
                        ref={$inputID}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row justify-content-end">
                    <label className="col-form-label col-sm-4">비밀번호</label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        ref={$inputPWD}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row justify-content-end">
                    <label className="col-form-label col-sm-4">
                      비밀번호 확인
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        ref={$inputPWDRe}
                      />
                    </div>
                    <label className="col-form-label col-sm-8 text-end text-danger">
                      입력시 비밀번호가 변경됩니다.
                    </label>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">담당자명</label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="User Name"
                        ref={$inputName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      담당자 전화번호
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Tel Number"
                        ref={$inputTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">Email</label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        ref={$inputEmail}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">가입일</label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="date"
                        disabled={false}
                        ref={$inputJoinDate}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">비고</label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows="3"
                        ref={$inputEtc}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">사용유무</label>
                    <div className="col-sm-8">
                      <select className="form-control" ref={$inputWorkYN}>
                        <option value="">미선택</option>
                        <option value="Y">사용</option>
                        <option value="N">중지</option>
                      </select>
                    </div>
                  </div>
                  <fieldset className="mb-3">
                    <div className="row">
                      <label className="col-form-label col-sm-4 pt-sm-0">
                        사용자 권한
                      </label>
                      <div className="col-sm-8">
                        <select className="form-control" ref={$inputLevel}>
                          <option value="">미선택</option>
                          <option value="0">가맹점</option>
                          <option value="1">관리자</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">회사명</label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사명"
                        ref={uCoName}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">대표자명</label>
                    <div className="col-sm-8 input-group">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="대표자명"
                        ref={uCoCEOName}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            if (!userData["currentData"].uCoCEOIDCard) return;
                            const fileName =
                              userData["currentData"].uCoCEOIDCard.split(
                                "?"
                              )[1];
                            window.open(
                              "//cardlaw.co.kr/uploadFiles/attach/" + fileName,
                              "_blank",
                              "width=800, height=1200"
                            );
                          }}
                        >
                          대표신분증 보기
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">회사주소</label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사주소"
                        ref={uCoAddr}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      사업자등록번호
                    </label>
                    <div className="col-sm-8">
                      <div className="input-group">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="사업자등록번호"
                          ref={uCoRegNo}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              if (!userData["currentData"].uCoRegFile) return;
                              const fileName =
                                userData["currentData"].uCoRegFile.split(
                                  "?"
                                )[1];
                              window.open(
                                "//cardlaw.co.kr/uploadFiles/attach/" +
                                  fileName,
                                "_blank",
                                "width=800, height=1200"
                              );
                            }}
                          >
                            사업자등록증 보기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      회사연락처
                    </label>
                    <div className="col-sm-8 ">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사연락처"
                        ref={uCoTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">통장 정보</label>
                    <div className="col-sm-8 input-group">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="회사연락처"
                        readOnly={true}
                        value={`(${
                          userData["currentData"] &&
                          userData["currentData"].uCoBankName
                        })${
                          userData["currentData"] &&
                          userData["currentData"].uCoBankNO
                        }`}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            if (!userData["currentData"].uCoBankBookPic) return;
                            const fileName =
                              userData["currentData"].uCoBankBookPic.split(
                                "?"
                              )[1];
                            window.open(
                              "//cardlaw.co.kr/uploadFiles/attach/" + fileName,
                              "_blank",
                              "width=800, height=1200"
                            );
                          }}
                        >
                          통장사본 보기
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      전용카드 PG ID
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="전용카드 PG ID"
                        ref={uPGIDSamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      전용카드 PG 암호화 Key
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="전용카드 PG 암호화 Key"
                        ref={uPGKeySamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      일반 PG ID
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="일반 PG ID"
                        ref={uPGIDNoneSamsung}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4">
                      일반 PG 암호화 Key
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="일반 PG 암호화 Key"
                        ref={uPGKeyNoneSamsung}
                      />
                    </div>
                  </div>
                  */}

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4 font-weight-bold">
                      승인 여부
                    </label>
                    <div className="col-sm-8">
                      <select className="form-select" ref={uAgreeYN}>
                        <option value="N">신규가입</option>
                        <option value="R">승인요청</option>
                        <option value="Y">승인</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary custom-btn-dark"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                {loading ? (
                  <i className="fa fa-circle-notch mr-2 rotate-infinite"></i>
                ) : (
                  <i className="fa fa-check mr-2" />
                )}
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(UserManagementEdit);
