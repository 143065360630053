import React, { useEffect, useState } from "react";
import "./CommonSlideNavbarCustomStyle.css";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import CodeList from "../lib/CodeList";
import Utilities from "../lib/Utilities";
const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "CommonSlideNavbar";

function CommonSlideNavbar(props) {
  const [loginInfo, setLoginInfo] = useState({ uLevel: 0 });

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setLoginInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        //console.log("CommonSlideNavbar EVENT_LOGIN_AUTO_SUCCESS", n);
        setLoginInfo(n.returnData[0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        codeList.Modal.current.alert("로그인 후 이용해주십시요", () => {
          //window.location.replace("/");
        });
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  return (
    <>
      <ul
        id="accordionSidebar"
        className="navbar-nav sidebar sidebar-light accordion custom-navbar-slider"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
        >
          <div className="sidebar-brand-icon" />
          <div className="sidebar-brand-text font-weight-bold h4 mx-3">
            CARDLAW
          </div>
        </a>

        {props.pages.map((data, index) => {
          if (loginInfo.uLevel >= data.uLevel) {
            if (data.hasOwnProperty("page")) {
              return (
                <li
                  key={index}
                  id={`btnNav${data.name}`}
                  className={`nav-item ${
                    props.pagesOpen[data.name] ? "active" : ""
                  }`}
                  onClick={() => props.PageChangeHandler(data.name)}
                >
                  <a className="nav-link" href="#">
                    <span>
                      {data.title}
                      {data.name === "MyInfoManage" &&
                      loginInfo.uAgreeYN === "Y"
                        ? " (결제 활성화)"
                        : ""}
                    </span>
                  </a>
                </li>
              );
            } else {
              return (
                <div key={index}>
                  <hr className="sidebar-divider" />
                  <div className="sidebar-heading">{data.title}</div>
                </div>
              );
            }
          }
        })}
        <li className="custom-navbar-slider-user-info bg-navbar text-white ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p>
                  상호
                  <br />
                  {loginInfo.uAgreeYN === "Y"
                    ? loginInfo["uCoName"] || "-"
                    : "-"}
                </p>
              </div>
              <div className="col-12">
                <p>
                  대표이사
                  <br />
                  {loginInfo.uAgreeYN === "Y"
                    ? loginInfo["uCoCEOName"] || "-"
                    : "-"}
                </p>
              </div>
              <div className="col-12">
                <p>
                  사업자 등록번호
                  <br />
                  {loginInfo.uAgreeYN === "Y"
                    ? loginInfo["uCoRegNo"] || "-"
                    : "-"}
                </p>
              </div>
              <div className="col-12">
                <p>
                  전화 번호
                  <br />
                  {loginInfo.uAgreeYN === "Y"
                    ? loginInfo["uTel"]
                      ? util.telFormatConvert(loginInfo["uTel"])
                      : "-"
                    : "-"}
                </p>
              </div>
              <div className="col-12">
                <p>
                  주소
                  <br />
                  {loginInfo.uAgreeYN === "Y"
                    ? loginInfo["uCoAddr"] || "-"
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}
/*
상호: ㈜카드로지스 대표이사:김도윤
주소 : 인천광역시 중구 흰바위로59번길8, 219호(운서동)
사업자등록번호 : 810-87-02575
고객센터 : 032-747-0211 | e-mail : cardlogis@gmail.com
 */

export default CommonSlideNavbar;
