import React, { useRef } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { CommonConstList } from "./CodeList";

class CommonUI extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  openModal = (type, title, message, placeholder = "", callback) => {
    this.modal.current.openModal(
      type,
      title,
      message,
      (placeholder = ""),
      callback
    );
  };

  alert = (message, callback) => {
    this.modal.current.alert(message, callback);
  };

  confirm = (message, callback) => {
    this.modal.current.confirm(message, callback);
  };

  render() {
    return (
      <>
        <Modal ref={this.modal} />
      </>
    );
  }
}

export default CommonUI;
