import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermsNav({ pages }) {
  let [activeMenu, setActiveMenu] = useState("");

  const ActiveMobileNav = () => {
    document.getElementById("navbar").classList.toggle("navbar-mobile");
    document.getElementById("mobile-nav-toggle").classList.toggle("bi-list");
    document.getElementById("mobile-nav-toggle").classList.toggle("bi-x");
  };

  return (
    <>
      <div
        id="header"
        className="fixed-top"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="container d-flex align-items-center justify-content-lg-between">
          <h1 className="logo me-auto me-lg-0">
            <Link to="/#hero">
              <img src="logo/CARDLAW_logo_horizontal(white).png" />
            </Link>
          </h1>
          <nav id="navbar" className="navbar order-last order-lg-0 ">
            <ul className="mr-4">
              {pages.map((page) => {
                return (
                  <li>
                    <a
                      className={`nav-link scrollto ${
                        activeMenu === page.id ? "active" : ""
                      }`}
                      href={`/Terms#${page.id}`}
                      onClick={() => {
                        let val =
                          document.getElementById("navbar").classList.value;
                        if (val.indexOf("navbar-mobile") !== -1) {
                          ActiveMobileNav();
                        }
                      }}
                    >
                      {page.name}
                    </a>
                  </li>
                );
              })}
            </ul>

            <i
              id="mobile-nav-toggle"
              className="bi bi-list mobile-nav-toggle"
              onClick={() => {
                ActiveMobileNav();
              }}
            />
          </nav>
        </div>
      </div>
    </>
  );
}
