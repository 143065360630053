import React, { useEffect, useState, useRef } from "react";
import Utilities from "../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonHeader from "../CommonHeader";
import FaqManageTable from "./FaqManageTable";
import { Link } from "react-router-dom";
import axios from "axios";
import CodeList from "../../lib/CodeList";
const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};

function FaqManage(props) {
  let $searchText = useRef();
  let $searchLevel = useRef();

  const addNewData = (e) => {
    console.log(e);
    socket.sendLocalMessage(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      "FaqManage",
      {},
      () => {}
    );

    //params.openEdit(e, params)
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_FAQ_MANAGE_FILTER_CHANGED,
      "FaqManage",
      filters,
      () => {}
    );

    console.log("searchFilterChanged");
  };

  const downloadExcel = (e) => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//cardlaw.co.kr/backside/faq/JS_faq_excel_export.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              SEARCH_WORD: $searchText.current.value,
              SEARCH_LEVEL: $searchLevel.current.value,
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `FAQ리스트_${util.todayDate("YYYYMMDD")}.xls`
            );
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong>FAQ 관리</strong>
                </h3>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    {/*필터 영역*/}
                    <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                      {/*간편기간 영역*/}

                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <div
                          className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                          role="group"
                        >
                          <button
                            className="btn custom-btn-info"
                            data-toggle="modal"
                            data-target="#staticFaqEditModal"
                            onClick={addNewData}
                            alt={"신규등록"}
                            aria-label={"신규등록"}
                            type="button"
                          >
                            <i className="fas fa-plus" /> 신규등록
                          </button>

                          <button
                            className="btn custom-btn-info"
                            onClick={downloadExcel}
                          >
                            <i className="fas fa-download mr-2" />
                            엑셀파일로 받기
                          </button>
                        </div>

                        <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                          <select
                            className="custom-select form-control"
                            ref={$searchLevel}
                            onChange={searchFilterChanged}
                            style={{ minWidth: 150 }}
                          >
                            <option value="">분류</option>
                            <option value="FJA0">서비스문의</option>
                            <option value="FJA1">가맹점문의</option>
                            <option value="FJA2">기타문의</option>
                          </select>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어"
                            aria-label="검색어"
                            aria-describedby="btnGroupAddon2"
                            onKeyPress={(e) => {
                              console.log(e);
                              if (e.charCode === 13) {
                                searchFilterChanged(e);
                              }
                            }}
                            ref={(ref) => ($searchText.current = ref)}
                          />

                          <button
                            className="input-group-text btn btn-primary custom-btn-dark"
                            onClick={searchFilterChanged}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </button>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <FaqManageTable />
                  {/*<Table
                      columns={columns}
                      data={data}
                      id="gridAccount"
                      ref={$grid}
                    />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqManage;
