import React, { useState, useEffect, useRef } from "react";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

export default function CardLawUser() {
  const [users, setUsers] = useState([
    {
      bBranchTYPE: "0변호사 1변리사",
      bUserName: "변호사 이름",
      bCEOName: "출신",
      bService: "전문분야",
      bBranchName: "소속로펌",
      bTel: "전화번호",
      bEmail: "이메일",
      bMemo: "소개글",
      bLogo: "사진",
      bHomepage: "홈페이지 및 SNS",
    },
  ]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/branch_pp/JS_branch_pp_select_by_home.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setUsers([...newData["returnData"]]);
        }
      }
    });
  };

  return (
    <>
      <section id="cardLawUser" className="team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>파트너소개</h2>
            <p>CARDLAW 파트너 법률대리인</p>
          </div>

          <div className="row">
            {users.map((user, index) => {
              let delay = (index + 1) * 100 + "";
              return (
                <div
                  key={index}
                  className="col-xl-3 col-md-6 d-flex align-items-stretch"
                >
                  <div
                    className="member"
                    data-aos="fade-up"
                    data-aos-delay={delay}
                  >
                    <div className="member-img">
                      <img
                        src={`//cardlaw.co.kr/uploadFiles/logo/${user.bLogo}`}
                        className="img-fluid"
                        alt={user.bUserName}
                      />
                    </div>
                    <div className="member-info py-3">
                      <span>
                        <h4 className="d-inline">{user.bUserName}</h4> (
                        {user.bBranchTYPE === "1" ? "변리사" : "변호사"})
                      </span>
                      <h6>
                        <strong>{user.bMemo}</strong>
                      </h6>
                      <span>
                        <strong>전문 분야 : </strong>
                        {user.bService}
                      </span>
                      <span>
                        <strong>소속 로펌 : </strong>
                        {user.bBranchName}
                      </span>
                      <span>
                        <strong>출신 : </strong>
                        {user.bCEOName}
                      </span>
                      <span>
                        <strong>tel : </strong>
                        {user.bTel}
                      </span>
                      <span>
                        <strong>e-mail : </strong>
                        {user.bEmail}
                      </span>
                      <span>
                        <strong>홈페이지 : </strong>
                        <a
                          className="text-primary text-decoration-underline"
                          href={
                            user.bHomepage.startsWith("http")
                              ? user.bHomepage
                              : `http://${user.bHomepage}`
                          }
                          target="_blank"
                        >
                          {user.bHomepage.startsWith("http")
                            ? user.bHomepage
                            : `http://${user.bHomepage}`}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
