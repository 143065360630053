import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import MainNav from "../main/MainNav";
import MainFooter from "../main/MainFooter";

import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function FindPW() {
  const [isLoading, setIsLoading] = useState(false);
  const controller = {
    user_id: useRef(),
    user_tel: useRef(),
    user_mail: useRef(),
  };

  function _requestTempPassword() {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/account/JS_find_password.php",
      msgID: msgID,
      data: {},
      user_id: controller.user_id.current.value.toUpperCase(),
      user_tel: controller.user_tel.current.value,
      user_mail: controller.user_mail.current.value,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        codeList.Modal.current.alert(newData["MSG"]);
        setIsLoading(false);
      }
    });
  }

  return (
    <div
      id="sample1"
      className=" d-flex flex-column h-100 bg-light"
      style={{ minHeight: "100vh" }}
    >
      <MainNav isNotShowGNB={true} />
      <div className="main flex-fill">
        <div
          className="flex-grow-1 d-flex flex-row justify-content-center align-items-center"
          style={{ marginTop: 80 }}
        >
          <div className="py-4" style={{ minWidth: 400 }}>
            <h5 className="font-weight-bold">비밀번호 찾기</h5>
            <p className="mb-0">
              등록된 이메일 주소로 임시 비밀번호가 전송됩니다.
            </p>
            <p>로그인 후 비밀번호 변경을 진행해주세요.</p>
            <input
              type="text"
              className="form-control rounded-0 mb-2"
              placeholder="아이디를 입력해주세요."
              ref={controller.user_id}
            />
            <input
              type="text"
              className="form-control rounded-0 mb-2"
              placeholder="등록한 이메일 주소를 입력해주세요"
              ref={controller.user_mail}
            />
            <input
              type="text"
              className="form-control rounded-0 mb-4"
              placeholder="등록한 연락처를 입력해주세요"
              ref={controller.user_tel}
            />
            <button
              className="w-100 btn btn-primary mb-2"
              disabled={isLoading}
              onClick={_requestTempPassword}
            >
              임시 비밀번호 전송
              {isLoading && (
                <i className="fas fa-spinner ml-2 rotate-infinite" />
              )}
            </button>
            <Link
              to="/"
              className="w-100 btn btn-outline-secondary"
              disabled={isLoading}
            >
              취소
            </Link>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}
