import React, { useState } from "react";
import MainNav from "./MainNav";
import MainCarousel from "./MainCarousel";
// import IntroduceCard from "./IntroduceCard";
import CardLawPartner from "./CardLawPartner";
import CardLawUser from "./CardLawUser";
import CardLawCharge from "./CardLawCharge";
import CardLawContact from "./CardLawContact";
import FaqCenter from "../FaqCenter/FaqCenter";
import MainFooter from "./MainFooter";
import "./main.css";

export default function Main() {
  return (
    <>
      <div
        id="sample1"
        className=" d-flex flex-column h-100"
        style={{ minHeight: "100vh" }}
      >
        <MainNav />
        <div className="main flex-fill">
          {window.location.hash !== "#faq" &&
          window.location.pathname === "/" ? (
            <>
              <MainCarousel />
              {/*<IntroduceCard />*/}
              <CardLawPartner />
              <CardLawUser />
              <CardLawCharge />
              <CardLawContact />
            </>
          ) : (
            <FaqCenter />
          )}
        </div>
        <MainFooter />
      </div>
    </>
  );
}
