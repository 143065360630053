import React from "react";

export default function CardLawCharge() {
  return (
    <section id="cardLawCharge" className="team">
      <div className="container" data-aos="fade-up">
        <div className="section-title pb-1">
          <h2>수수료 및 정산</h2>
          <p style={{ fontSize: "32px" }}>카드로 결제수수료 및 정산 안내</p>
        </div>

        <p className="text-right text-secondary mb-1">(부가세 별도)</p>
        <div className="row h5 mb-1 border mx-1 text-center font-weight-bold">
          <div className="col-4 border-bottom border-right p-3">구분</div>
          <div className="col-8 border-bottom p-3">결제수수료</div>
          <div className="col-4 border-bottom border-right p-3">
            카드로 가맹점
          </div>
          <div className="col-8 border-bottom p-3 d-flex flex-row justify-content-center align-items-center">
            <span className="h4 font-weight-bold mb-0">2.7%</span>
            <div className="d-flex flex-row justify-content-center align-items-center ml-2">
              <span className="h4 font-weight-bold mb-0">(</span>
              <div className="d-flex flex-row align-items-center mx-3">
                <div style={{ fontSize: "0.9rem" }}>
                  <p className="text-secondary m-0">2.7%</p>
                  <p className="text-secondary m-0">외부결제(PG수수료)</p>
                </div>
                <p className="m-0 mx-3 h4 mb-0 font-weight-bold">+</p>
                <div style={{ fontSize: "0.9rem" }}>
                  <p className="text-warning m-0">0%</p>
                  <p className="text-warning m-0">카드로 수수료</p>
                </div>
                <p></p>
              </div>
              <span className="h4 font-weight-bold mb-0">)</span>
            </div>
          </div>
          <div className="col-4 border-right p-3">정산 안내</div>
          <div className="col-8 p-3 d-flex flex-row align-items-center justify-content-center">
            D + 3일{" "}
            <span
              className="text-secondary ml-2"
              style={{ fontSize: "0.9rem" }}
            >
              (신용카드 승인일 기준, 주말 및 공휴일인 경우 익 영업일 정산)
            </span>
          </div>
        </div>
        <p className="px-1">
          ※ 카드로 가맹점의 결제대금은 PG사(결제대행사)의{" "}
          <img
            className="ml-2"
            alt="galaxia"
            src="/images/galaxia.png"
            height="48px"
          />
          <span style={{ marginLeft: -18 }}>
            를 통해 안전하게 지급대행 하고 있습니다.
          </span>
        </p>
      </div>
    </section>
  );
}
