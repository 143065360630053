import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import FaqManageEdit from "./FaqManageEdit";
const socket = new BuddibleSocket();
const util = new Utilities();

const AGENT_LEVEL = [
  { text: "가맹점", value: "0" },
  { text: "관리자", value: "1" },
  { text: "개발자", value: "3" },
];

const WORK_YN = [
  { text: "사용", value: "Y" },
  { text: "중지", value: "N" },
];

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

/*
ROWNUM, f.fIDX, f.fTITLE AS fTITLE , f.fCONTENTS AS fCONTENTS , f.fINSERTDATE, f.fUSERID,
          e.uName, fBtype, fMtype, fStype, fComment, fKeyword
          , b.cName AS bName, m.cName AS mName, s.cName AS sName
 */
const headCells = [
  {
    field: "fIDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sName",
    headerName: "분류",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "fTITLE",
    headerName: "제목",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "fINSERTDATE",
    headerName: "등록일",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    valueGetter: (params) => {
      return util.getDateToFormat(new Date(params.data), "YYYY-MM-DD");
    },
  },

  {
    field: "ROWNUM",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <button
          type="button"
          key={"btn-" + params.currentData.uID}
          className="btn btn-primary custom-btn-dark"
          data-toggle="modal"
          data-target="#staticFaqEditModal"
          onClick={(e) => params.openEdit(e, params)}
          alt={"수정"}
          aria-label={"수정"}
        >
          <i className="fas fa-pen " />
        </button>
      </span>
    ),
  },
];

function Row(props) {
  const { row, index, openEdit, isMultiSelect = false } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(index);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      onClick={(event) => handleClick(event, index)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.field}
          align={headCell.numeric ? "right" : "left"} //text 정렬
          padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
        >
          {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}

/*        <TableCell component="th" scope="row">
          {row.field}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>*/

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="custom-header-dark"
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

export default function FaqManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("uID");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "uID",
      sort: "asc",
    },
  ]);
  let filters = {};

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_FILTER_CHANGED,
      "FaqManageTable",
      (b, n) => {
        filters = n;
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED,
      "FaqManageTable",
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_FAQ_MANAGE_FILTER_CHANGED,
        "FaqManageTable"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED,
        "FaqManageTable"
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/faq/JS_faq_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          //let returnData = newData["ReturnData"];
          //newData.data = newData["ReturnData"];
          //newData["ReturnData"] = [];
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
          //console.log(ServerData);
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  /*
  const onFilterChange = React.useCallback((filterModel) => {
    setFilterValue(filterModel.items[0].value);
  }, []);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    console.log("handlePageChange", newPage);
    setPage(newPage);
  };
  const handleSortModelChange = (model) => {
    // We have the cursor, we can allow the page transition.
    console.log("handleSortModelChange", model);
    setSortModel(model);
  };
*/

  /*  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };*/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalCount.length - page * rowsPerPage);*/
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    //console.log("openEdit", e.target.dataset, params);
    editModal.current.openModal(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} openEdit={openEdit} />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} openEdit={openEdit} />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row key={index} row={row} index={index} openEdit={openEdit} />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={index} row={row} index={index} openEdit={openEdit} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <FaqManageEdit ref={editModal} userData={{ name: "aa" }} />
    </TableContainer>
  );
}

FaqManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
