import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import BranchManageTable from "./BranchManageTable";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function BranchManageEdit(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState("defaultLogo.png");

  const $controller = {
    bIDX: useRef(),
    bLogo: useRef(),
    bBranchName: useRef(),
    bBranchTYPE: useRef(),
    bCEOName: useRef(),
    bBranchDate: useRef(),
    bUserName: useRef(),
    bTel: useRef(),
    bEmail: useRef(),
    bMemo: useRef(),
    bAddrRoadAddress: useRef(),
    bShowYN: useRef(),
    bMapTime: useRef(),
    bMapKey: useRef(),
    bOrder: useRef(),
    bService: useRef(),
    bHomepage: useRef(),
  };

  const dataChange = (e, params) => {
    console.log(userData, params);
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      for (let key in $controller) {
        if (
          $controller.hasOwnProperty(key) &&
          userData.currentData.hasOwnProperty(key)
        ) {
          console.log(key, $controller[key]);
          if ($controller[key].current)
            $controller[key].current.value = userData.currentData[key];
        }
      }
      setLogoUrl(userData.currentData["bLogo"]);
    } else {
      for (let key in $controller) {
        if ($controller.hasOwnProperty(key)) {
          if ($controller[key].current) $controller[key].current.value = "";
        }
      }
      setLogoUrl("defaultLogo.png");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      "BranchManageEdit",
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
        "BranchManageEdit"
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    console.log(codeList.Modal.current);
    setLoading(true);
    if (checkValidation()) {
      let updateData = {};
      for (let key in $controller) {
        if ($controller.hasOwnProperty(key)) {
          if ($controller[key].current)
            updateData[key] = $controller[key].current.value;
        }
      }

      requestUserUpdate(
        updateData,
        $controller.bIDX.current.value !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const logoChanged = (e) => {
    console.log(e.target.files);
    requestPhotoUpload(e.target.files);
  };

  function requestPhotoUpload(filedata, callback) {
    let formData = new FormData();
    formData.append("fileToUpload", filedata[0]);

    axios
      .post(
        "//cardlaw.co.kr/backside/branch/JS_branch_upload_photo_save.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("axios handleData", response.data);
        if (response.data["ret"] === true) {
          setLogoUrl(response.data["file"]);
          $controller.bLogo.current.value = response.data["file"];
        } else {
          codeList.Modal.current.alert(
            "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
            () => {}
          );
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  }

  const checkValidation = () => {
    if ($controller.bBranchTYPE.current.value.trim() === "") {
      codeList.Modal.current.alert("타입을 선택해 주세요.", () => {});
      return false;
    }
    if ($controller.bBranchName.current.value.trim() === "") {
      codeList.Modal.current.alert("회사명은 필수 입력사항 입니다.", () => {});
      return false;
    }
    if ($controller.bShowYN.current.value.trim() === "") {
      codeList.Modal.current.alert("노출 여부를 선택해 주세요.", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/branch/JS_branch_update.php"
          : "/branch/JS_branch_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);

      if (newData) {
        console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_BRANCH_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_BRANCH_MANAGE_DATA_INSERTED,
            "UserManagementEdit",
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "정보 수정이 완료되었습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "정보 저장이 완료되었습니다.",
                () => {}
              );

          $("#BranchEditModalClose").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "저장 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "저장 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  const searchAddr = () => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        console.log(data);
        $controller.bAddrRoadAddress.current.value =
          data["userSelectedType"] === "J"
            ? data["address"]
            : data["roadAddress"];
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.
      },
    }).open();
  };

  return (
    <>
      <div
        className={"modal fade"}
        id="branchEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog" style={{ maxWidth: 1000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>가맹점 소개 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="BranchEditModalClose"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      관리번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="account"
                        className="form-control"
                        ref={$controller.bIDX}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <fieldset className="mb-3">
                    <div className="row">
                      <label className="col-form-label col-sm-3 text-sm-end pt-sm-0">
                        타입
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          ref={$controller.bBranchTYPE}
                        >
                          <option value="">미선택</option>
                          <option value="0">포워더</option>
                          <option value="1">관세사</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      회사명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bBranchName}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      서비스
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bService}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      설립일
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="date"
                        disabled={false}
                        ref={$controller.bBranchDate}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      대표자명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bCEOName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      담당자명
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bUserName}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      전화번호
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="tel"
                        className="form-control"
                        ref={$controller.bTel}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      E-mail
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        ref={$controller.bEmail}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      소개글
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        rows="5"
                        ref={$controller.bMemo}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      회사로고
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div
                          style={{
                            width: "100%",
                            height: "125px",
                            backgroundImage: `url('//cardlaw.co.kr/uploadFiles/logo/${logoUrl}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        />

                        <input
                          type="text"
                          className="form-control"
                          ref={$controller.bLogo}
                          disabled={true}
                        />
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={logoChanged.bind(this)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            권장사이즈 100x100 픽셀
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      홈페이지주소
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bHomepage}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      주소
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controller.bAddrRoadAddress}
                        />
                        <div className="input-group-append">
                          <span
                            className="btn input-group-text"
                            id="basic-addon2"
                            onClick={searchAddr}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      노출여부
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        ref={$controller.bShowYN}
                      >
                        <option value="">미선택</option>
                        <option value="Y">표시</option>
                        <option value="N">미표시</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      노출순서
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        ref={$controller.bOrder}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      지도 Time
                    </label>
                    <div className="col-sm-9">
                      <a href="https://map.kakao.com/" target="_blank">
                        지도검색 바로가기
                      </a>
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bMapTime}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-sm-end">
                      지도 Key
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.bMapKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary custom-btn-dark"
                onClick={(e) => actionSave(e)}
              >
                <i className="fa fa-check mr-2" />
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(BranchManageEdit);
