import React, { useState, useRef, useEffect } from "react";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

let socket = new BuddibleSocket();
let util = new Utilities();

export default function FaqCenter() {
  const [faqList, setFaqList] = useState([
    {
      name: "서비스 문의",
      key: "service",
      faq: [
        {
          frequently: "카드로지스는 어떤 서비스인가요?",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
    {
      name: "가맹점 문의",
      key: "franchisee",
      faq: [
        {
          frequently: "가맹점 문의 글 입니다.",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
    {
      name: "기타 문의",
      key: "etc",
      faq: [
        {
          frequently: "기타 문의 글 입니다.",
          questions:
            "카드로지스는 기업의 수출입 문류비를 현금이 아닌 카드로 지불할 수 있도록 해주는 서비스 입니다.",
        },
        {
          frequently: "카드 결제외에 어떤 기능이 있나요?",
          questions:
            "카드로지스에 가입하게 될 시 기간별 정산금액 조회, 한도조회, 결제 url을 통한 결제요청 기능 등을 사용 할 수 있습니다.",
        },
        {
          frequently: "카드로지스를 이용하기 위한 비용은 얼마인가요?",
          questions: "결제 방식에 따라 ....입니다.",
        },
        {
          frequently: "문의 글 입니다.",
          questions: "문의글에 대한 답변입니다.",
        },
      ],
    },
  ]);
  let [changeData, setChangeData] = useState(0);
  let [resetActive, setResetActive] = useState(false);

  const $controller = {
    searchFAQ: useRef(),
  };

  useEffect(() => {
    requestUserSelect();
    return () => {
      requestUserSelect();
    };
  }, []);

  const requestUserSelect = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/faq/JS_faq_select.php",
      msgID: msgID,
      data: {},
      take: 30,
      skip: 0,
      page: 1,
      pageSize: 30,
      sort: [
        {
          field: "fINSERTDATE",
          sort: "desc",
        },
      ],
      SEARCH_WORD: $controller.searchFAQ.current
        ? `${$controller.searchFAQ.current.value}`
        : "",
      USE_YN: "Y",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"]) {
          let dataList = [...newData["returnData"]];
          let setList = [
            {
              name: "서비스 문의",
              key: "service",
              faq: [],
            },
            {
              name: "가맹점 문의",
              key: "franchisee",
              faq: [],
            },
            {
              name: "기타 문의",
              key: "etc",
              faq: [],
            },
          ];
          dataList.forEach((data) => {
            switch (data.fStype) {
              case "FJA0":
                setList[0].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
              case "FJA1":
                setList[1].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
              case "FJA2":
                setList[2].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
            }
          });
          if (callback) {
            callback();
          }
          $controller.searchFAQ.current.value = "";
          setFaqList(setList);
          setChangeData(changeData + 1);
        }
      }
    });
  };

  const resetRequestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/faq/JS_faq_select.php",
      msgID: msgID,
      data: {},
      take: 30,
      skip: 0,
      page: 1,
      pageSize: 30,
      sort: [
        {
          field: "fOrder",
          sort: "asc",
        },
      ],
      SEARCH_WORD: "",
      USE_YN: "Y",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          let dataList = [...newData["returnData"]];
          let setList = [
            {
              name: "서비스 문의",
              key: "service",
              faq: [],
            },
            {
              name: "가맹점 문의",
              key: "franchisee",
              faq: [],
            },
            {
              name: "기타 문의",
              key: "etc",
              faq: [],
            },
          ];
          dataList.forEach((data) => {
            switch (data.fStype) {
              case "FJA0":
                setList[0].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
              case "FJA1":
                setList[1].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
              case "FJA2":
                setList[2].faq.push({
                  frequently: data.fTITLE,
                  questions: data.fCONTENTS,
                });
                break;
            }
          });
          $controller.searchFAQ.current.value = "";
          setFaqList(setList);
          setResetActive(false);
          setChangeData(changeData + 1);
        }
      }
    });
  };

  return (
    <section id="faq" className="faq section container">
      <div className="container">
        <div className="col-12 text-center h2 font-weight-bold text-white mb-5">
          무엇을 도와드릴까요?
        </div>
        <div className="col-12">
          <div
            className="row testimonials"
            style={{
              background: `url("assets/img/testimonials-bg.jpg") 50% 50% /cover no-repeat`,
            }}
          >
            <div className="col-0 col-lg-3" />
            <div className="col-12 col-lg-6">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 text-center">
                    <h1 className="font-weight-bold text-white">도움말 센터</h1>
                  </div>
                  <div className="col-12 p-0">
                    <div className="faq-search">
                      <div className="faq-search-wrap">
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          placeholder="검색어를 입력해주세요"
                          ref={$controller.searchFAQ}
                          onKeyPress={(e) => {
                            if (e.charCode === 13) {
                              requestUserSelect(setResetActive(true));
                            }
                          }}
                        />
                        <input
                          className="font-weight-bold"
                          type="submit"
                          value="검색"
                          onClick={() => {
                            requestUserSelect();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {resetActive && (
                    <div className="col-12 my-3 d-flex justify-content-center">
                      <span
                        className="btn btn-secondary"
                        onClick={() => {
                          resetRequestUserSelect();
                        }}
                      >
                        초기화
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-0 col-lg-3" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <ul
              className="nav nav-tabs custom-nav-tabs"
              id="myTab"
              role="tablist"
            >
              {faqList.map((menu, index) => {
                return (
                  <li
                    className={`nav-item custom-nav-item `}
                    role="presentation"
                    key={menu.key}
                  >
                    <a
                      className={`nav-link custom-nav-link font-weight-bold ${
                        index === 0 ? "active" : ""
                      }`}
                      id={`${menu.key}-tab`}
                      data-toggle="tab"
                      href={`#${menu.key}`}
                      role="tab"
                      aria-controls={menu.key}
                      aria-selected="true"
                    >
                      {menu.name}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content" id="myTabContent">
              {faqList.map((data, index) => {
                return (
                  <div
                    className={`tab-pane fade ${
                      index === 0 ? " show active" : ""
                    }`}
                    id={data.key}
                    key={data.key}
                    role="tabpanel"
                    aria-labelledby={`${data.key}-tab`}
                  >
                    <div
                      className="accordion custom-accordion"
                      id={`faq_${data.key}`}
                    >
                      {data.faq.length > 0 ? (
                        data.faq.map((faqData, index) => {
                          return (
                            <div key={index} className="card custom-card">
                              <div
                                className="card-header custom-card-header"
                                id={`heading${data.key + index} `}
                              >
                                <h2 className="mb-0">
                                  <div
                                    className="btn btn-link custom-btn-link btn-block text-left font-weight-bold collapsed d-flex justify-content-between"
                                    data-toggle="collapse"
                                    data-target={`#collapse${data.key + index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${
                                      data.key + index
                                    }`}
                                  >
                                    <span>{faqData.frequently}</span>
                                    <i className="fas fa-chevron-down align-self-center pl-3" />
                                  </div>
                                </h2>
                              </div>
                              <div
                                id={`collapse${data.key + index}`}
                                className="collapse custom-questions"
                                aria-labelledby={`heading${data.key + index}`}
                                data-parent={`#faq_${data.key}`}
                              >
                                <div className="card-body">
                                  <div
                                    className="p-4 text-secondary"
                                    style={{
                                      whiteSpace: "break-spaces",
                                    }}
                                  >
                                    {faqData.questions}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="container bg-light">
                          <div className="row">
                            <div className="col text-center p-5 h4">
                              문의 사항이 없습니다.
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
