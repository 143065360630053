import React from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";

let socket = new BuddibleSocket();
let util = new Utilities();

export default class CodeList {
  constructor(data) {
    if (CodeList.exists) {
      return CodeList.instance;
    }
    CodeList.instance = this;
    CodeList.exists = true;
    this.codeCountry = new Map();
    this.codeLanguage = new Map();
    this.codeSubject = new Map();
    this.codeHashtag = new Map();
    this.codeHashtagObj = [];
    this.codeHashtagByName = new Map();
    this.codeOffer = new Map();

    this.publicVar = {
      imgServerUrl: "https://msgserver.buddible.com:4000",
    };
    this.Modal = React.createRef();

    this.setModal = (e) => {
      CodeList.instance.Modal.current = e;
    };

    return this;
  }
  requestHashtagList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      method: "GetHashtagList",
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          this.codeHashtagObj = [];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            this.codeHashtag.set(obj.htc_key, obj.htc_name);
            this.codeHashtagByName.set(obj.htc_name, obj.htc_key);
            this.codeHashtagObj.push({
              htc_key: obj.htc_key,
              htc_name: obj.htc_name,
            });
          }
          if (callback) callback(true);
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_HASHTAG,
            "InitCodeList",
            this.codeHashtag,
            () => {}
          );
        }
      }
    });
  };
}

export const CommonConstList = {
  Modal: {
    TYPE_ALERT: "TYPE_ALERT",
    TYPE_CONFIRM: "TYPE_CONFIRM",
    TYPE_INPUT: "TYPE_INPUT",
  },
  RouteOffer: {
    OFFER_FOOD: "OFFER_FOOD",
    OFFER_DRINK: "OFFER_DRINK",
    OFFER_TICKET: "OFFER_TICKET",
    OFFER_EQUIP: "OFFER_EQUIP",
    OFFER_DRIVER: "OFFER_DRIVER",
  },
  Accessibility: {
    ACCESS_MAIN: "ACCESS_MAIN",
    ACCESS_ETC: "ACCESS_ETC",
    ACCESS_COMM: "ACCESS_COMM",
    ACCESS_ENV: "ACCESS_ENV",
  },
};
