import React, { useEffect, useState } from "react";
import CommonSlideNavbar from "./CommonSlideNavbar";
import PaymentRequestList from "./paymentRequestList/PaymentRequestList";
import PaymentRequestRegister from "./paymentRequestRegist/PaymentRequestRegister";
import UserManagement from "./userManagement/UserManagement";
import BranchManage from "./branchManage/BranchManage";
import FaqManage from "./faqManage/FaqManage";
import MyInfoManage from "./myInfo/MyInfoManage";
// import LimitInquiry from "./limitInquiry/LimitInquiry";
import "./css/ruang-admin.css";
import CommonHeader from "./CommonHeader";
import AutoLogin from "../lib/AutoLogin";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import BranchPPManage from "./branchPP/BranchPPManage";
import "./PaymentPlatform.css";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "PaymentPlatform";

function PaymentPlatform({ history, match }) {
  const [userInfo, setUserInfo] = useState(null);
  let [pagesOpen, setPagesOpen] = useState({
    ManagePaymentRequests: true,
    PaymentRequest: false,
    LimitInquiry: false,
    UserManagement: false,
    BranchManage: false,
    BranchPPManage: false,
    FaqManage: false,
    MyInfoManage: false,
  });
  const [pages, setPages] = useState([
    { name: "type", title: "결제요청", uLevel: 0 },
    {
      name: "ManagePaymentRequests",
      title: "결제 요청서 관리",
      page: (params) => <PaymentRequestList {...params} />,
      uLevel: 0,
    },
    {
      name: "PaymentRequest",
      title: "결제 요청서 등록",
      page: (params) => <PaymentRequestRegister {...params} />,
      uLevel: 0,
    },
    { name: "type", title: "관리자메뉴", uLevel: 1 },
    {
      name: "UserManagement",
      title: "사용자 계정관리",
      page: (params) => <UserManagement {...params} />,
      uLevel: 1,
    },
    {
      name: "BranchManage",
      title: "가맹점 소개관리",
      page: (params) => <BranchManage {...params} />,
      uLevel: 1,
    },
    {
      name: "BranchPPManage",
      title: "법률대리인 소개관리",
      page: (params) => <BranchPPManage {...params} />,
      uLevel: 1,
    },
    {
      name: "FaqManage",
      title: "FAQ 관리",
      page: (params) => <FaqManage {...params} />,
      uLevel: 1,
    },
    { name: "type", title: "계정 관리", uLevel: 0 },

    {
      name: "MyInfoManage",
      title: "내 정보 관리",
      page: (params) => <MyInfoManage {...params} />,
      uLevel: 0,
    },
  ]);

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (beforeData, newData) => {
        if (newData.ret) {
          setUserInfo({ ...newData["returnData"][0] });
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (beforeData, newData) => {
        codeList.Modal.current.alert("로그인 후 이용해주세요", () => {
          //history.replace("/");
        });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  let PageChangeHandler = (name) => {
    let pageList = { ...pagesOpen };
    for (let key in pageList) {
      pageList[key] = key === name;
    }
    setPagesOpen(pageList);
  };

  if (userInfo === null) {
    return (
      <>
        <AutoLogin />
      </>
    );
  }

  return (
    <div id="paymentPlatform">
      <div id="wrapper" className="wrapper">
        <AutoLogin />
        <CommonSlideNavbar
          PageChangeHandler={PageChangeHandler}
          pages={pages}
          pagesOpen={pagesOpen}
        />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <CommonHeader />
            {pages.map((p) => {
              if (userInfo.uLevel >= p.uLevel && p.name !== "type") {
                return (
                  <div
                    key={p.name}
                    style={
                      !pagesOpen[p.name] ? { display: "none" } : { display: "" }
                    }
                    className="main"
                  >
                    {p.page({ userInfo: userInfo })}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentPlatform;
